import React, { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../../Context';

import { Row, Col, Select, Typography, Space, Spin,  Tooltip,Card, Breadcrumb, Input, Button, Modal, Slider, Switch, Divider, message, Avatar } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { AreaChartOutlined,HomeOutlined,FullscreenOutlined,FullscreenExitOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';

import Plot from 'react-plotly.js';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useLocation, useNavigate } from 'react-router-dom';
import sandingressData from './reducer';
import { DAS_SOCKET_DATA, DTS_SOCKET_DATA, SAND_INGRSS_GRAPH_CONFIG } from './constants';
import axios from 'axios';
import { useFormik } from 'formik';
// import * as d3 from 'd3';

const { Title } = Typography;

// const unpack = (rows, key) => rows.map(row => row[key]);


export default function SandIngressVisualization() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	// const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const { wellinfo, getWellInfo, wellzoneinfo,getWellZoneInfo} = useAuth();	
	// const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const { state } = useLocation();
	const navigate = useNavigate();
	const [spin, setSpin] = useState(false);

	const [fullsize, setfullSize] = useState(false);
	const [graph, setGraph] = useState(null);

	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));


	const [wellId, setWellId] = useState();
	const [dassocketurl, setDasSocketUrl] = useState(null);
	const [dtssocketurl, setDtsSocketUrl] = useState(null);
	const [depthRangeFrom, setDepthRangeFrom] = useState(null);
	const [depthRangeTo, setDepthRangeTo] = useState(null);

	const [zoneId, setZoneId] = useState(null);
	const [zonefrom, setZoneFrom] = useState(null);
	const [zoneto, setZoneTo] = useState(null);


	const [tempTh, setTempTH] =useState({'maxTH':null, 'minTH':null});
	const [ampTh, setAmpTH] =useState({'maxTH':null, 'minTH':null});

	const [tempSNTh, setSNTempTH] =useState({'maxTH':null, 'minTH':null});
	const [ampSNTh, setSNAmpTH] =useState({'maxTH':null, 'minTH':null});

	const [wellFrom, setWellFrom] = useState(null);
	const [wellTo, setWellTo] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [drawerDepthFrom, setDrawerDepthFrom] = useState();
	const [drawerDepthTo, setDrawerDepthTo] = useState();

	const [dasSocket, setDasSocket] = useState();
	const [dtsSocket, setDtsSocket] = useState();

	let dtsEnabled1 =false;
	let dasEnabled1 =false;

	const initalState = {details: null, pending: false, error: false, dtsData:null, dasData:null, pressData:null, graphConfig : {} };
	const [data, dispatch] = useReducer(sandingressData, initalState);	

	// const dasEnabled = true;
	// const dtsEnabled = true;
	
	let tempWellId;
	let tempdasurl;
	let tempdtsurl;

	let tempstartDepth;
	let tempendDepth;

	let tempdtsminth; 
	let tempdtsmaxth;
	let tempdasminth;
	let tempdasmaxth; 


	let tempdtsSNminth; 
	let tempdtsSNmaxth;
	let tempdasSNminth;
	let tempdasSNmaxth; 

	let socketDTS = null;
	let socketDAS = null;


	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;

	const handleSetWell = (e , options) =>{	
		
		dispatch({type: DAS_SOCKET_DATA, payload:[]});
		dispatch({type: DTS_SOCKET_DATA, payload:[]});

		if(dasSocket){
			dasSocket.close();
		}
		if(dtsSocket) {
			dtsSocket.close();
		}


		tempWellId=e;
		setWellId(e);

		setDasSocketUrl(`ws://${options.dasip}:${options.dasport}`);
		setDtsSocketUrl(`ws://${options.dasip}:${options.dasport}`);

		setDepthRangeFrom(options.startDepth);
		setDepthRangeTo(options.endDepth);
		setZoneId(null);
		setZoneFrom(null);
		setZoneTo(null);
		setWellFrom(options.startDepth);
		setWellTo(options.endDepth);

		setTempTH({'maxTH':options.dtsmaxth, 'minTH':options.dtsminth});
		setAmpTH({'maxTH':options.dasmaxth, 'minTH':options.dasminth});

		setSNTempTH({'maxTH':options.dtsSNmaxth, 'minTH':options.dtsSNminth});
		setSNAmpTH({'maxTH':options.dasSNmaxth, 'minTH':options.dasSNminth});

		setdtsEnabled(options.dtsEnabled);
		setdasEnabled(options.dasEnabled);

		setDrawerDepthFrom(options.startDepth);
		setDrawerDepthTo(options.startDepth);
		
	};
	
	
	const welldata =[];

	wellinfo && wellinfo.map((item,index) => {

		if(item&&item.sandingressconfig){

			welldata.push({
				value : item.id,
				label : item.name.toUpperCase(),
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				dasip: item && item.sandingressconfig &&item.sandingressconfig.dasip,
				dasport : item && item.sandingressconfig &&item.sandingressconfig.dasport,
				dtsip: item && item.sandingressconfig &&item.sandingressconfig.dtsip,
				dtsport : item && item.sandingressconfig &&item.sandingressconfig.dtsport,
				dtsminth : item && item.sandingressconfig && item.sandingressconfig.tempThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.defaultminThreshold ,
				dtsmaxth : item && item.sandingressconfig && item.sandingressconfig.tempThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.defaultmaxThreshold,
				dasminth : item && item.sandingressconfig && item.sandingressconfig.ampThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.defaultminThreshold,
				dasmaxth : item && item.sandingressconfig && item.sandingressconfig.ampThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.defaultmaxThreshold,
				presminth :item && item.sandingressconfig && item.sandingressconfig.pressThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.pressThresholds && item.sandingressconfig.pressThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.pressThresholds && item.sandingressconfig.pressThresholds.defaultminThreshold,
				presmaxth : item && item.sandingressconfig && item.sandingressconfig.pressThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.pressThresholds && item.sandingressconfig.pressThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.pressThresholds && item.sandingressconfig.pressThresholds.defaultmaxThreshold,
				pressip: item && item.sandingressconfig &&item.sandingressconfig.pressureip,
				pressport : item && item.sandingressconfig &&item.sandingressconfig.pressureport,
				dtsEnabled : item.dtsEnabled,
				dasEnabled : item.dasEnabled,

				dtsSNminth : item && item.sandingressconfig && item.sandingressconfig.tempSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.defaultminThreshold ,
				dtsSNmaxth : item && item.sandingressconfig && item.sandingressconfig.tempSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.defaultmaxThreshold,

				dasSNminth : item && item.sandingressconfig && item.sandingressconfig.ampSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.defaultminThreshold,
				dasSNmaxth : item && item.sandingressconfig && item.sandingressconfig.ampSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.defaultmaxThreshold,
			});
			if (index === 0) {		
					
				tempWellId = item.id;
				tempstartDepth = item.startDepth;
				tempendDepth = item.endDepth;
				tempdasurl = `ws://${item && item.sandingressconfig &&item.sandingressconfig.dasip}:${item && item.sandingressconfig &&item.sandingressconfig.dasport}`;
				tempdtsurl = `ws://${item && item.sandingressconfig &&item.sandingressconfig.dtsip}:${item && item.sandingressconfig &&item.sandingressconfig.dtsport}`;
				tempdtsminth = item && item.sandingressconfig && item.sandingressconfig.tempThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.defaultminThreshold;
				tempdtsmaxth = item && item.sandingressconfig && item.sandingressconfig.tempThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.tempThresholds && item.sandingressconfig.tempThresholds.defaultmaxThreshold;
				tempdasminth = item && item.sandingressconfig && item.sandingressconfig.ampThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.defaultminThreshold;
				tempdasmaxth = item && item.sandingressconfig && item.sandingressconfig.ampThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.ampThresholds && item.sandingressconfig.ampThresholds.defaultmaxThreshold;
				dtsEnabled1 = item.dtsEnabled;
				dasEnabled1 = item.dasEnabled;

				tempdtsSNminth = item && item.sandingressconfig && item.sandingressconfig.tempSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.defaultminThreshold;
				tempdtsSNmaxth = item && item.sandingressconfig && item.sandingressconfig.tempSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.tempSNThresholds && item.sandingressconfig.tempSNThresholds.defaultmaxThreshold;

				tempdasSNminth = item && item.sandingressconfig && item.sandingressconfig.ampSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.minThreshold : item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.defaultminThreshold;
				tempdasSNmaxth = item && item.sandingressconfig && item.sandingressconfig.ampSNThresholdsOverride ?  item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.maxThreshold : item.sandingressconfig && item.sandingressconfig.ampSNThresholds && item.sandingressconfig.ampSNThresholds.defaultmaxThreshold;
			}

		}
	});
	

	const [dtsEnabled, setdtsEnabled] = useState();
	const [dasEnabled, setdasEnabled] = useState();	

	useEffect(() => {
		setdtsEnabled(dtsEnabled1);
		setdasEnabled(dasEnabled1);
		setDepthRangeFrom(tempstartDepth);
		setDepthRangeTo(tempendDepth);
	},[ dtsEnabled1, dasEnabled1, tempstartDepth, tempendDepth]);


	const zonelist =[{
		value:null,label:'ALL',zoneFrom:wellFrom ,zoneTo:wellTo
	}];

	wellzoneinfo && wellzoneinfo.map((item)=>{
		zonelist.push({
			value : item.id,
			label : item.zoneName.toUpperCase(),
			zoneFrom:item.zoneFrom,
			zoneTo:item.zoneTo
		});
	});
	
	useEffect(() => {
		let params = '';		
		if(wellId || tempWellId) {
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/sandingressconfig?${params}`,
				headers: { 
					Authorization:`Bearer ${authtoken}`	
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status == 200) {
						dispatch({ type : SAND_INGRSS_GRAPH_CONFIG, payload : response.data });
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},[wellId, tempWellId]);

	useEffect(() => {
		getWellInfo(authtoken);
		getWellZoneInfo(authtoken,wellId ? wellId : tempWellId);

		// setWellId(tempWellId);
		// setSocketUrl(tempurl);

	},[wellId,dassocketurl,dtssocketurl,tempWellId,dasEnabled,dtsEnabled, tempTh,ampTh,tempdtsminth, tempdtsmaxth, tempdasminth, tempdasmaxth,tempdasSNmaxth, tempdasSNminth, tempdtsSNmaxth, tempdtsSNminth, tempSNTh, ampSNTh]);

	const webSocketDataDAS = () =>{		

		// const socket1 = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
		// socket1.close();

		if(socketDAS==null){

		
			socketDAS = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
			setDasSocket(socketDAS);
			setSpin(true);		
	
			socketDAS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};
	
			socketDAS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					dispatch({type: DAS_SOCKET_DATA, payload:newData});

				
					// setWebsocketDASData(newData);
					setSpin(false);
	
				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};
	
			socketDAS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};
	
			socketDAS.onclose = () => {
			// setTimeout(setSpin(false), 5000);	
			// setTimeout(()=> {
			// 	webSocketDataDAS();
			// }, 10000);	
				setSpin(false);
				dispatch({type: DAS_SOCKET_DATA, payload:[]});
				console.log('WebSocket connection closed');
			};		
	
			const handlePopState = () => {			
				socketDAS.close();
				dispatch({type: DAS_SOCKET_DATA, payload:[]});
				console.log('Route changed to:', window.location.pathname);
			};

			const handleUrlChange = () => {
				if (socketDAS) {
					socketDAS.close();
					dispatch({type: DAS_SOCKET_DATA, payload:[]});
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDAS.close();
				dispatch({type: DAS_SOCKET_DATA, payload:[]});
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};

		}

	};
	
	useEffect(() => {

		if((dassocketurl || tempdasurl) && (dasEnabled == true || dasEnabled1 == true)){	
			webSocketDataDAS();
		}
	
	}, [dassocketurl,tempdasurl]);
	
	
	useEffect(() => {

		if((dtssocketurl || tempdtsurl) && (dtsEnabled == true || dtsEnabled1 == true)){			

			webSocketDataDTS();
		}
	
	}, [dtssocketurl,tempdtsurl]);
	


	const webSocketDataDTS =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL

		if(socketDTS==null){

		

			socketDTS = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl);
			setDtsSocket(socketDTS);
			setSpin(true);		

			socketDTS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};

			socketDTS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					// console.log('new Data', newData);	
				
			
					dispatch({type: DTS_SOCKET_DATA, payload:newData});
					setSpin(false);

				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};

			socketDTS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};

			socketDTS.onclose = () => {
				// setTimeout(setSpin(false), 5000);
			// setTimeout(()=> {
			// 	webSocketDataDTS();
			// }, 10000);		
				setSpin(false);
				dispatch({type: DTS_SOCKET_DATA, payload:[]});
				console.log('WebSocket connection closed');
			};		

			const handlePopState = () => {			
				socketDTS.close();
				dispatch({type: DTS_SOCKET_DATA, payload:[]});
				console.log('Route changed to:', window.location.pathname);
			};

			const handleUrlChange = () => {
				if (socketDTS) {
					socketDTS.close();
					dispatch({type: DTS_SOCKET_DATA, payload:[]});
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDTS.close();
				dispatch({type: DTS_SOCKET_DATA, payload:[]});
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};

		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [fullsize]);

	const handlefullSize = (size,graph) =>{
		setfullSize(size);
		setGraph(graph);
	};	

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	// const dasData = [{
	// 	type: 'scatter',
	// 	mode: 'lines',
	// 	x: websocketdata.line_data,
	// 	y: websocketdata.depths,
	// 	line: { color: '#17BECF' },
	// 	name: 'Amplitude',
		
	// }];

	const snRatioDASData =  [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.dasData && data.dasData.snr,
		y: data && data.dasData && data.dasData.depths,
		line: { color: '#17BECF' },
		name: 'S/N Ratio',
		
	}];

	const snRatioDTSData =  [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.dtsData && data.dtsData.snr,
		y: data && data.dtsData && data.dtsData.depths,
		line: { color: '#b32d1e ' },
		name: 'S/N Ratio',
		
	}];


	const dtsData = [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.dtsData && data.dtsData.line_data,
		y: data && data.dtsData && data.dtsData.depths,
		line: { color: '#b32d1e' },
		name: 'Temperature',
		
	}];

	const amplitudeDASData =  [{
		type: 'scatter',
		mode: 'lines',
		x: data && data.dasData && data.dasData.line_data,
		y: data && data.dasData && data.dasData.depths,
		line: { color: '#17BECF' },
		name: 'Amplitude',
		
	}];
	
	

	const plotDtsSingalData = [{

		z:data && data.dtsData && data.dtsData.data,
		y:data && data.dtsData && data.dtsData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dtsData && data.dtsData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Hot',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		reversescale:true
	}];

	const plotDASSignalData = [{

		z:data && data.dasData && data.dasData.data,
		y:data && data.dasData && data.dasData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dasData && data.dasData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		
	},{
		type: 'scatter',
		// z:websocketdata.data,
		x: data && data.dasData && data.dasData.anomaly_times,
		y: data && data.dasData && data.dasData.anomaly_depths,
		mode: 'markers',
		// transforms: [{
		// 	type: 'filter',
		// 	target: 'y',
		// 	operation: '>',
		// // value: 4
		// }],
		marker: {
			size: 20, // Increase the size of the points
			color: 'rgba(135, 206, 250, 0.5)', // Optional: Change point color if needed
			symbol : 'circle',
			font: {
				color: 'black', // Set the text color
			},
		},
		name: data && data.dasData && data.dasData.anomaly_type, // Legend entry name for scatter plot
		// hoverlabel: {
		// 	bgcolor: 'white', // Set the tooltip background color
		// 	font: {
		// 		color: 'black', // Set the text color
		// 	},
		// }
	}];	

	const filteredSignalDASData = [{

		z:data && data.dasData && data.dasData.data,
		y:data && data.dasData && data.dasData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dasData && data.dasData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		
	}];


	const filteredSignalDTSData = [{

		z:data && data.dtsData && data.dtsData.data,
		y:data && data.dtsData && data.dtsData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dtsData && data.dtsData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Hot',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		reversescale:true
		
	}];


	const filteredSignalDTS = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.FilteredSignalDTS ? langdata.FilteredSignalDTS :  'Filtered Signal (DTS)',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ]
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};

	const filteredSignalDAS = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.FilteredSignalDAS ? langdata.FilteredSignalDAS :  'Filtered Signal (DAS)',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ]
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};

	// const daslayout = {
	// 	// title: 'Flow Monitoring',
	// 	title: {
	// 		text: langdata && langdata.DASSoundWaves ? langdata.DASSoundWaves :  'DAS Sound Waves',
	// 		font: {
	// 			size: 20,
	// 			// family: 'Arial, sans-serif',
	// 			// color: 'black',
	// 			weight: 'bold'
	// 		},
	// 	},
	// 	fontWeight: 800,
	// 	autosize: true,
	// 	xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
	// 	yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
	// 		// autorange: 'reversed',
	// 		range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ 350 , 0 ]
	// 	},
	// 	// paper_bgcolor: 'transparent', // Remove background color of the entire chart
	// 	// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	// };


	const dasSignal = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.DASSignal ? langdata.DASSignal : 'DAS Signal',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ]
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};
	const DTSTemperature = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.DTSTemperature ? langdata.DTSTemperature : 'DTS Temperature',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ]
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};


	const dasAmplitude = {
		title: {
			text: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude ',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: true,
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			// range: [86.8700008333, 138.870004167],
			// range: [350, 70],
			type: 'linear'
		},

		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
				y0: 0,
				x1: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
				y0: 0,
				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},

			//max threshold
			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,

				y0: 0,

				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				// editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'bottom center',

				},

			},

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,

				y0: 0,

				x1: ampTh.maxTH || ampTh.maxTH == 0 ? ampTh.maxTH : tempdasmaxth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'top center',

				},

			},


			//min threshold

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,

				y0: 0,

				x1: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				// editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'bottom center',

				},

			},

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,

				y0: 0,

				x1:ampTh.minTH || ampTh.minTH == 0 ? ampTh.minTH : tempdasminth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'top center',

				},

			},

		],
		showlegend:true
	};

	// const [dasData, setDataDAS] = useState([]);

	// const [dtsData, setDataDTS] = useState([]);
	const dtsTemperature = {
		title: {
			text: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: true,
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			// range: [86.8700008333, 138.870004167],
			// range: [350, 70],
			type: 'linear'
		},
		// legend: {
		// 	'orientation': 'h'
		// },

		// margin: {
		// 	l: 50, r: 70, b: 50, t: 50, pad: 2
		// },

		// yaxis2: {
		// 	title: '[kN.m/(m/s)²]',
		// 	overlaying: 'y',
		// 	side: 'right'
		// },

		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
				y0: 0,
				x1: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
				y0: 0,
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			//max threhold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y0: 0,
		
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'bottom center',
		
				},
		
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y0: 0,
		
				x1: tempTh.maxTH || tempTh.maxTH == 0 ? tempTh.maxTH : tempdtsmaxth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'top center',
		
				},
		
			},


			//min threshold

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y0: 0,
		
				x1: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				// editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'bottom center',
		
				},
		
			},

			{

				type: 'rect',
		
				xref: 'x',
		
				yref: 'paper',
		
				x0: tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y0: 0,
		
				x1:tempTh.minTH || tempTh.minTH == 0 ? tempTh.minTH : tempdtsminth,
		
				y1: 1,
		
				fillcolor: 'transparent',
		
				opacity: 0.2,
		
				editable: true,
		
				line: {
		
					width: 0,
		
				},
		
				label: {
		
					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',
		
					font: { size: 10, color: 'red' },
		
					textposition: 'top center',
		
				},
		
			},


		],
		showlegend:true


		

		// annotations : [
		// 	{
		// 		x: 100, // Position annotation at the end of the x-axis range
		// 		y: 140,  // Y-coordinate for the annotation
		// 		xanchor: 'right',
		// 		yanchor: 'top',
		// 		text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
		// 		showarrow: true,
		// 		arrowhead: 2,
		// 		ax: -10, // X-offset for the annotation
		// 		ay: 0,   // Y-offset for the annotation
		// 		font: {
		// 			color: 'red',
		// 			size: 12,
		// 		},
		// 	},
		// 	{
		// 		x: 100, // Position annotation at the end of the x-axis range
		// 		y: 80,  // Y-coordinate for the annotation
		// 		xanchor: 'right',
		// 		yanchor: 'top',
		// 		text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
		// 		showarrow: true,
		// 		arrowhead: 2,
		// 		ax: -10, // X-offset for the annotation
		// 		ay: 0,   // Y-offset for the annotation
		// 		font: {
		// 			color: 'blue',
		// 			size: 12,
		// 		},
		// 	},
		// ],

		// showlegend: true,
	};

	const daslinelayout = {
		title: {
			text: langdata && langdata.SignaltoNoiseDAS ? langdata.SignaltoNoiseDAS : 'S/N Ratio (DAS)',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},

		
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.SNR ? langdata.SNR : 'SNR',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			// range: [86.8700008333, 138.870004167],
			// range: [350, 0],
			type: 'linear'
		},

		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampSNTh.minTH || ampSNTh.minTH == 0 ? ampSNTh.minTH : tempdasSNminth,
				y0: 0,
				x1: ampSNTh.minTH || ampSNTh.minTH == 0 ? ampSNTh.minTH : tempdasSNminth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: ampSNTh.maxTH || ampSNTh.maxTH == 0 ? ampSNTh.maxTH : tempdasSNmaxth,
				y0: 0,
				x1: ampSNTh.maxTH || ampSNTh.maxTH == 0 ? ampSNTh.maxTH : tempdasSNmaxth,
				y1: 1,
				line:{
					color: '#14877c',
					width: 2,
					dash:'dot'
				}
			},

			//max threshold
			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampSNTh.maxTH || ampSNTh.maxTH == 0 ? ampSNTh.maxTH : tempdasSNmaxth,

				y0: 0,

				x1: ampSNTh.maxTH || ampSNTh.maxTH == 0 ? ampSNTh.maxTH : tempdasSNmaxth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				// editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'bottom center',

				},

			},

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampSNTh.maxTH || ampSNTh.maxTH == 0 ? ampSNTh.maxTH : tempdasSNmaxth,

				y0: 0,

				x1: ampSNTh.maxTH || ampSNTh.maxTH == 0 ? ampSNTh.maxTH : tempdasSNmaxth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'top center',

				},

			},


			//min threshold

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampSNTh.minTH || ampSNTh.minTH == 0 ? ampSNTh.minTH : tempdasSNminth,

				y0: 0,

				x1: ampSNTh.minTH || ampSNTh.minTH == 0 ? ampSNTh.minTH : tempdasSNminth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				// editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'bottom center',

				},

			},

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: ampSNTh.minTH || ampSNTh.minTH == 0 ? ampSNTh.minTH : tempdasSNminth,

				y0: 0,

				x1:ampSNTh.minTH || ampSNTh.minTH == 0 ? ampSNTh.minTH : tempdasSNminth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'top center',

				},

			},

		],
		showlegend:true
	};

	const dtslinelayout = {
		title: {
			text: langdata && langdata.SignaltoNoiseDTS ? langdata.SignaltoNoiseDTS : 'S/N Ratio (DTS)',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},

		
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			title: langdata && langdata.SNR ? langdata.SNR : 'SNR',
			type: 'linear'
		},
		yaxis: {
			title: langdata && langdata.Depth ? langdata.Depth :'Depth',
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
			// range: [86.8700008333, 138.870004167],
			// range: [350, 0],
			type: 'linear'
		},

		shapes: [
			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempSNTh.minTH || tempSNTh.minTH == 0 ? tempSNTh.minTH : tempdtsSNminth,
				y0: 0,
				x1: tempSNTh.minTH || tempSNTh.minTH == 0 ? tempSNTh.minTH : tempdtsSNminth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			{
				type: 'line',
				xref: 'x',
				yref: 'paper',
				x0: tempSNTh.maxTH || tempSNTh.maxTH == 0 ? tempSNTh.maxTH : tempdtsSNmaxth,
				y0: 0,
				x1: tempSNTh.maxTH || tempSNTh.maxTH == 0 ? tempSNTh.maxTH : tempdtsSNmaxth,
				y1: 1,
				line:{
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash:'dot'
				}
			},

			//max threshold
			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: tempSNTh.maxTH || tempSNTh.maxTH == 0 ? tempSNTh.maxTH : tempdtsSNmaxth,

				y0: 0,

				x1: tempSNTh.maxTH || tempSNTh.maxTH == 0 ? tempSNTh.maxTH : tempdtsSNmaxth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				// editable: true,

				line: {

					width: 0,

				},

				label: {

					text:  langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'bottom center',

				},

			},

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: tempSNTh.maxTH || tempSNTh.maxTH == 0 ? tempSNTh.maxTH : tempdtsSNmaxth,

				y0: 0,

				x1: tempSNTh.maxTH || tempSNTh.maxTH == 0 ? tempSNTh.maxTH : tempdtsSNmaxth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'top center',

				},

			},


			//min threshold

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: tempSNTh.minTH || tempSNTh.minTH == 0 ? tempSNTh.minTH : tempdtsSNminth,

				y0: 0,

				x1: tempSNTh.minTH || tempSNTh.minTH == 0 ? tempSNTh.minTH : tempdtsSNminth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				// editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'bottom center',

				},

			},

			{

				type: 'rect',

				xref: 'x',

				yref: 'paper',

				x0: tempSNTh.minTH || tempSNTh.minTH == 0 ? tempSNTh.minTH : tempdtsSNminth,

				y0: 0,

				x1:tempSNTh.minTH || tempSNTh.minTH == 0 ? tempSNTh.minTH : tempdtsSNminth,

				y1: 1,

				fillcolor: 'transparent',

				opacity: 0.2,

				editable: true,

				line: {

					width: 0,

				},

				label: {

					text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold',

					font: { size: 10, color: 'red' },

					textposition: 'top center',

				},

			},

		],
		showlegend:true
	};

	const handleRangefrom = (e) => {				
		if(e.target.value) {
			setDepthRangeFrom(parseInt(e.target.value));
			setDrawerDepthFrom(parseInt(e.target.value));
		} else {
			setDepthRangeFrom();
			setDrawerDepthFrom();
		}
	};

	const handleRangeTo = (e) => {
		if(e.target.value) {
			setDepthRangeTo(e.target.value);
			setDrawerDepthTo(e.target.value);
		} else {
			setDepthRangeTo();
			setDrawerDepthTo();
		}
	};			

	const handleSetZone = (e,options)=>{
		
		setZoneId(e);
		setZoneFrom(options.zoneFrom);
		setDepthRangeFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setZoneTo(options.zoneTo);
		setDepthRangeTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);

		setDrawerDepthFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setDrawerDepthTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);

	};

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleDepthChange = (value) => {
		setDrawerDepthFrom(value[0]);
		setDrawerDepthTo(value[1]);
	};
	
	const formik = useFormik({
		enableReinitialize: true,
		initialValues : {
			id : data.graphConfig && data.graphConfig.id,
			WellId : data.graphConfig && data.graphConfig.wellinfoId,
			minAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.minThreshold,
			maxAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.maxThreshold,
			minAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.minThreshold,
			maxAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.maxThreshold,
			minAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.minThreshold,
			maxAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.maxThreshold,
			minTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.minThreshold,
			maxTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.maxThreshold,
			minTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.minThreshold,
			maxTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.maxThreshold,
			minTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.minThreshold,
			maxTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.maxThreshold,
			minPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.minThreshold,
			maxPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.maxThreshold,
			minPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.minThreshold,
			maxPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.maxThreshold,
			minPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.minThreshold,
			maxPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.maxThreshold,
			ampThresholdsOverride : data.graphConfig && data.graphConfig.ampThresholdsOverride,
			tempThresholdsOverride : data.graphConfig && data.graphConfig.tempThresholdsOverride,
			pressThresholdsOverride : data.graphConfig && data.graphConfig.pressThresholdsOverride,
			ampSNThresholdsOverride : data.graphConfig && data.graphConfig.ampSNThresholdsOverride,
			tempSNThresholdsOverride : data.graphConfig && data.graphConfig.tempSNThresholdsOverride,
			pressSNThresholdsOverride : data.graphConfig && data.graphConfig.pressSNThresholdsOverride,
			ampFreqThresholdsOverride : data.graphConfig && data.graphConfig.ampFreqThresholdsOverride,
			tempFreqThresholdsOverride : data.graphConfig && data.graphConfig.tempFreqThresholdsOverride,
			pressFreqThresholdsOverride : data.graphConfig && data.graphConfig.pressFreqThresholdsOverride,
			defaultminAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.defaultminThreshold,
			defaultmaxAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.defaultmaxThreshold,
			defaultminAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.defaultminThreshold,
			defaultmaxAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.defaultmaxThreshold,
			defaultminAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.defaultminThreshold,
			defaultmaxAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.defaultmaxThreshold,
			defaultminTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.defaultminThreshold,
			defaultmaxTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.defaultmaxThreshold,
			defaultminTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.defaultminThreshold,
			defaultmaxTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.defaultmaxThreshold,
			defaultminTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.defaultminThreshold,
			defaultmaxTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.defaultmaxThreshold,
			defaultminPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.defaultminThreshold,
			defaultmaxPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.defaultmaxThreshold,
			defaultminPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.defaultminThreshold,
			defaultmaxPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.defaultmaxThreshold,
			defaultminPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.defaultminThreshold,
			defaultmaxPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.defaultmaxThreshold,
		},
		onSubmit: () => {
			
			const id = parseInt(formik.values.id);
			let data = JSON.stringify({
				'ampThresholds': {
					'maxThreshold': formik.values.maxAmpThreshold,
					'minThreshold': formik.values.minAmpThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxAmpThreshold,
					'defaultminThreshold': formik.values.defaultminAmpThreshold
				},
				'ampThresholdsOverride': formik.values.ampThresholdsOverride ,
				'tempThresholds': {
					'maxThreshold': formik.values.maxTempThreshold,
					'minThreshold': formik.values.minTempThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxTempThreshold,
					'defaultminThreshold': formik.values.defaultminTempThreshold
				},
				'tempThresholdsOverride': formik.values.tempThresholdsOverride ,
				'ampSNThresholds': {
					'maxThreshold': formik.values.maxAmpSNThresholds,
					'minThreshold': formik.values.minAmpSNThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxAmpSNThresholds,
					'defaultminThreshold': formik.values.defaultminAmpSNThresholds
				},
				'ampSNThresholdsOverride': formik.values.ampSNThresholdsOverride ,
				'tempSNThresholds': {
					'maxThreshold': formik.values.maxTempSNThresholds,
					'minThreshold': formik.values.minTempSNThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxTempSNThresholds,
					'defaultminThreshold': formik.values.defaultminTempSNThresholds
				},
				'tempSNThresholdsOverride': formik.values.tempSNThresholdsOverride ,
				'ampFreqThresholds': {
					'maxThreshold': formik.values.maxAmpFreqThresholds,
					'minThreshold': formik.values.minAmpFreqThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxAmpFreqThresholds,
					'defaultminThreshold': formik.values.defaultminAmpFreqThresholds
				},
				'ampFreqThresholdsOverride': formik.values.ampFreqThresholdsOverride ,
				'tempFreqThresholds': {
					'maxThreshold': formik.values.maxTempFreqThresholds,
					'minThreshold': formik.values.minTempFreqThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxTempFreqThresholds,
					'defaultminThreshold': formik.values.defaultminTempFreqThresholds
				},
				'tempFreqThresholdsOverride': formik.values.tempFreqThresholdsOverride ,
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/sandingressconfig/${id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`	
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						getWellInfo(authtoken);
						Modal.success({ title: langdata && langdata.Graphdetailssuccessfullyupdated ? langdata.Graphdetailssuccessfullyupdated : 'Graph details successfully updated.' });
						setIsModalOpen(false);
						setDepthRangeFrom(drawerDepthFrom);
						setDepthRangeTo(drawerDepthTo);
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });

					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });
					}
				});
		}
	});			
	
	const depthMarks = {
		[wellFrom ? wellFrom : tempstartDepth] : langdata && langdata.Min ? langdata.Min : 'Min',
		[wellTo ? wellTo : tempendDepth] :langdata && langdata.Max ? langdata.Max : 'Max', 
		[drawerDepthFrom] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthFrom}</span>},
		[drawerDepthTo] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthTo}</span>},
	};	

	const ampMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}</span>},
		[formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}</span>}
	};		

	const dasSNRatioMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 :  langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.ampSNThresholdsOverride == true ? formik.values.minAmpSNThresholds : formik.values.defaultminAmpSNThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampSNThresholdsOverride == true ? formik.values.minAmpSNThresholds :  formik.values.defaultminAmpSNThresholds}</span>},
		[formik.values.ampSNThresholdsOverride == true ? formik.values.maxAmpSNThresholds : formik.values.defaultmaxAmpSNThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampSNThresholdsOverride == true ? formik.values.maxAmpSNThresholds : formik.values.defaultmaxAmpSNThresholds}</span>}
	};	

	const dasFrequencyMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds}</span>} ,
		[formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds}</span>}
	};	

	const tempMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold}</span>},
		[formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold}</span>}
	};	

	const dtsSNRatioMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.tempSNThresholdsOverride == true ? formik.values.minTempSNThresholds : formik.values.defaultminTempSNThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempSNThresholdsOverride == true ? formik.values.minTempSNThresholds : formik.values.defaultminTempSNThresholds}</span>},
		[formik.values.tempSNThresholdsOverride == true ? formik.values.maxTempSNThresholds : formik.values.defaultmaxTempSNThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempSNThresholdsOverride == true ? formik.values.maxTempSNThresholds : formik.values.defaultmaxTempSNThresholds}</span>}
	};	

	const dtsFrequencyMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds}</span>},
		[formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds}</span>}
	};		

	const handleReset = () => {
		formik.resetForm();
		setDrawerDepthFrom(wellFrom ? wellFrom : tempstartDepth);
		setDrawerDepthTo(wellTo ? wellTo : tempendDepth);
	};
	
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.SandIngress ? langdata.SandIngress : 'Sand Ingress'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId ? wellId : tempWellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyzone ? langdata.Filterbyzone : 'Filter by zone'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
											optionFilterProp="children"
											defaultValuevalue={null}
											value={zoneId}
											options={zonelist}
											onSelect={(e, options)=>handleSetZone(e, options)}
										/>
									</Space>
								</Tooltip>
								{dasEnabled == true || dtsEnabled == true ?
									<>
										<Space>&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.EnterDepthRange ? langdata.EnterDepthRange:'Enter Depth Range'}>
											<Space.Compact size="middle" style={{ width : '180px', margin:'5px'}}>
												<Input type='number' value={depthRangeFrom} max={zonefrom} min={zoneto} placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} onChange={handleRangefrom} />
												<Input type='number' value={depthRangeTo} max={zonefrom} min={zoneto} placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} onChange={handleRangeTo}/>
											</Space.Compact>
										</Tooltip>
										<Space>&nbsp;</Space>
										<Space>
											<Button style ={{margin:'5px'}} type='primary' onClick={showModal}><FilterOutlined /></Button>
										</Space>
									</>
									: null }
							</Col>
						</Row>
					</Col>
				</Row>
				{/* <Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
					width : '100%'
				}} > */}
				<Row style={{
					margin: '20px',
					marginTop : '5px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}} >
					<Col span={24}>
						<Row>
							<Breadcrumb
								items={[
									{
										href: '/dashboard',
										title: <HomeOutlined />,
									},
									{
										title: ( state && state.path === 'well-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
									},
									{
										title: `${langdata && langdata.SandIngress ? langdata.SandIngress:'SandIngress'}`,
									},
								]}
								style={{ marginBottom : '10px'}}
							/>
							{fullsize==false ?
								<>
									{dasEnabled == true ?

										<Card style={{ width: '100%', height: '700px' }}>
											<Row>
												{/* <Card style={{ width : '100%', height : '600px'}}>
									<Card.Grid 
										style={{ width : '100%', height : '600px'}}> */}
								
												<Col span={6} style={{padding:'10px'}}>
													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
 
														{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
														{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
													<> */}
														
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasSignal');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
														<p style={{marginTop:'-25px',marginLeft:'-30px', fontSize : '10px'}}
															// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
															className="wellInfo" >
															{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
															<b style={{fontSize : '12px'}}>Currently Viewing</b>
															<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
														</p>
														<Plot
															data={plotDASSignalData}
															layout={dasSignal}
															style={{ width: '100%', height: '600px' }}
															config={{
																// displayModeBar: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
						
															}}
														/>
														{/* </> 
													:<Empty style={{ width: '100%', height: '100%', minHeight:'600px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
												} */}
														{/* </div> */}
														{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
													</Spin>
												</Col>
								
												<Col span={6} style={{padding:'10px'}}> 
													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											
												
														{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
														{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?

													<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'filteredSignalDAS');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
											
														<Plot
															data={filteredSignalDASData}
															layout={filteredSignalDAS}
															style={{ width: '100%', height: '600px' }}
															config={{
																// displayModeBar: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
						
															}}
														/>
														{/* </>
													:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
												} */}
													</Spin>
											
												</Col>

												<Col span={6} style={{padding:'10px'}}> 
													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

														{/* { dtsData && dtsData.length > 0 ?
											
													<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasAmplitude');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
														<Plot
															data={amplitudeDASData}
															layout={dasAmplitude}
															// config={{  }}
															style={{ width: '100%', height: '600px' }}
															config={{
																// displayModeBar: true,
																responsive: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
						
															}}
														/>
												
														{/* </>
													: 

													<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
												} */}
													</Spin>
												</Col>


												<Col span={6} style={{padding:'10px'}}> 

													<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
														{/* { dasData && dasData.length > 0 ?
											
													<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasline');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
										
														<Plot
															data={snRatioDASData}
															layout={daslinelayout}
															// config={{ responsive: true }}
															style={{ width: '100%', height: '600px' }}
															config={{
																// displayModeBar: true,
																responsive: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
						
															}}
														/>
												
														{/* </>
													: 

													<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
												} */}
														{/* </div> */}
													</Spin>

												</Col>
												{/* <Space>&nbsp;</Space> */}

										
											</Row>
											{/* </Card.Grid>

								</Card> */}
										</Card>
										: null }

									{ dtsEnabled == true ?
									
										<Card style={{ width: '100%', height: '700px', marginTop : dasEnabled == true ? '15px' : '0px' }}>
											<Row>
												{/* <Card style={{ width : '100%', height : '600px'}}>
								<Card.Grid 
									style={{ width : '100%', height : '600px'}}> */}
							
												<Col span={6} style={{padding:'10px'}}>
													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

														{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
														{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
												<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'DTSTemperature');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
														<p style={{marginTop:'-25px',marginLeft:'-35px', fontSize : '10px'}}
															// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
															className="wellInfo" >
															{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
															<b style={{fontSize : '12px'}}>Currently Viewing</b>
															<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name} <br />
														</p>
										
														<Plot
															data={plotDtsSingalData}
															layout={DTSTemperature}
															style={{ width: '100%', height: '600px' }}
															config={{
															// displayModeBar: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
					
															}}
														/>
														{/* </> 
												:<Empty style={{ width: '100%', height: '100%', minHeight:'600px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
											} */}
														{/* </div> */}
														{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
													</Spin>
												</Col>
							
												<Col span={6} style={{padding:'10px'}}> 
													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

										
											
														{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
														{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?

												<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'filteredSignalDTS');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
										
														<Plot
															data={filteredSignalDTSData}
															layout={filteredSignalDTS}
															style={{ width: '100%', height: '600px' }}
															config={{
															// displayModeBar: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
					
															}}
														/>
														{/* </>
												:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
											} */}
													</Spin>
										
												</Col>

												<Col span={6} style={{padding:'10px'}}> 
													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

														{/* { dtsData && dtsData.length > 0 ?
										
												<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtsTemperature');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
														<Plot
															data={dtsData}
															layout={dtsTemperature}
															// config={{  }}
															style={{ width: '100%', height: '600px' }}
															config={{
															// displayModeBar: true,
																responsive: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
					
															}}
														/>
											
														{/* </>
												: 

												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
											} */}
													</Spin>
												</Col>


												<Col span={6} style={{padding:'10px'}}> 

													<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
														{/* { dasData && dasData.length > 0 ?
										
												<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtslinelayout');}} 
															className="graphZoomButton" ><FullscreenOutlined /></button>
									
														<Plot
															data={snRatioDTSData}
															layout={dtslinelayout}
															// config={{ responsive: true }}
															style={{ width: '100%', height: '600px' }}
															config={{
															// displayModeBar: true,
																responsive: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
					
															}}
														/>
											
														{/* </>
												: 

												<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
											} */}
														{/* </div> */}
													</Spin>

												</Col>
												{/* <Space>&nbsp;</Space> */}

									
											</Row>
											{/* </Card.Grid>

							</Card> */}
										</Card>
										: null }
								</>
								:
								graph =='dasSignal' ? 
									<Col span={24} >
										<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
											{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
												<> */}
											<button
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasSignal');}} 
												className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
												// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
												className="wellInfo" >
												{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
												<b style={{fontSize : '12px'}}>Currently Viewing</b>
												<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
											</p>
											<Plot
												data={plotDASSignalData}
												layout={dasSignal}
												style={{ width: '100%', height: '800px' }}
												config={{
													// displayModeBar: true,
													displaylogo : false,
													modeBarButtonsToRemove: [
														'pan2d',
														'zoom2d',
														'select2d',
														'zoomIn2d',
														'zoomOut2d',
														'autoScale2d',
														'resetScale2d',
														'hoverClosestCartesian',
														'hoverCompareCartesian',
														'zoom3d',
														'pan3d',
														'resetViews3d',
														'hoverClosest3d',
														'hoverCompare3d',
														'orbitRotation',
														'tableRotation',
														'resetCameraDefault3d',
														'resetCameraLastSave3d',
														'lasso2d'
													],
				
												}}
											/>
											{/* </>
												:<Empty style={{ width: '100%', height: '100%', minHeight:'600px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
											} */}
											{/* </div> */}
											{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
										</Spin>
									</Col>
									:	
									graph =='filteredSignalDTS' ? 
										<Col span={24} > 
											<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											
												
												{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
												{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
													<> */}
												<button
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'filteredSignalDTS');}} 
													className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
												<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
													// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
													className="wellInfo" >
													{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
													<b style={{fontSize : '12px'}}>Currently Viewing</b>
													<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name} <br />
												</p>
												<Plot
													data={filteredSignalDTSData}
													layout={filteredSignalDTS}
													style={{ width: '100%', height: '800px' }}
													config={{
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
						
													}}
												/>
												{/* </>

													:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
												} */}
											</Spin>
											
										</Col>
										: graph =='dasAmplitude' ? 
											<Col span={24}> 
												<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
											
													{/* { dtsData && dtsData.length > 0 ?
														<> */}
													<button
														onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasAmplitude');}} 
														className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
													<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
														// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
														className="wellInfo" >
														{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
														<b style={{fontSize : '12px'}}>Currently Viewing</b>
														<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
													</p>
													<Plot
														data={amplitudeDASData}
														layout={dasAmplitude}
														// config={{  }}
														style={{ width: '100%', height: '800px' }}
														config={{
															// displayModeBar: true,
															responsive: true,
															displaylogo : false,
															modeBarButtonsToRemove: [
																'pan2d',
																'zoom2d',
																'select2d',
																'zoomIn2d',
																'zoomOut2d',
																'autoScale2d',
																'resetScale2d',
																'hoverClosestCartesian',
																'hoverCompareCartesian',
																'zoom3d',
																'pan3d',
																'resetViews3d',
																'hoverClosest3d',
																'hoverCompare3d',
																'orbitRotation',
																'tableRotation',
																'resetCameraDefault3d',
																'resetCameraLastSave3d',
																'lasso2d'
															],
						
														}}
													/>
														
													{/* </>
														: 
										
														<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
													} */}
												</Spin>
											</Col>
											: graph =='dasline' ? 

												<Col span={24} > 

													<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
														{/* { dasData && dasData.length > 0 ? 
															<> */}
														<button
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasline');}} 
															className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
														<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
															// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
															className="wellInfo" >
															{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
															<b style={{fontSize : '12px'}}>Currently Viewing</b>
															<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
														</p>
														<Plot
															data={snRatioDASData}
															layout={daslinelayout}
															// config={{ responsive: true }}
															style={{ width: '100%', height: '800px' }}
															config={{
																// displayModeBar: true,
																responsive: true,
																displaylogo : false,
																modeBarButtonsToRemove: [
																	'pan2d',
																	'zoom2d',
																	'select2d',
																	'zoomIn2d',
																	'zoomOut2d',
																	'autoScale2d',
																	'resetScale2d',
																	'hoverClosestCartesian',
																	'hoverCompareCartesian',
																	'zoom3d',
																	'pan3d',
																	'resetViews3d',
																	'hoverClosest3d',
																	'hoverCompare3d',
																	'orbitRotation',
																	'tableRotation',
																	'resetCameraDefault3d',
																	'resetCameraLastSave3d',
																	'lasso2d'
																],
						
															}}
														/>
														{/* </>
												
															: 
										
															<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
														} */}
														{/* </div> */}
													</Spin>

												</Col>

												:
												graph =='DTSTemperature' ? 
													<Col span={24} >
														<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

															{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
															{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
												<> */}
															<button
																onClick={()=>{handlefullSize(fullsize === false ? true:false,'DTSTemperature');}} 
																className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
															<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
																// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																className="wellInfo" >
																{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																<b style={{fontSize : '12px'}}>Currently Viewing</b>
																<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name} <br />
															</p>
															<Plot
																data={plotDtsSingalData}
																layout={DTSTemperature}
																style={{ width: '100%', height: '800px' }}
																config={{
																	// displayModeBar: true,
																	displaylogo : false,
																	modeBarButtonsToRemove: [
																		'pan2d',
																		'zoom2d',
																		'select2d',
																		'zoomIn2d',
																		'zoomOut2d',
																		'autoScale2d',
																		'resetScale2d',
																		'hoverClosestCartesian',
																		'hoverCompareCartesian',
																		'zoom3d',
																		'pan3d',
																		'resetViews3d',
																		'hoverClosest3d',
																		'hoverCompare3d',
																		'orbitRotation',
																		'tableRotation',
																		'resetCameraDefault3d',
																		'resetCameraLastSave3d',
																		'lasso2d'
																	],
				
																}}
															/>
															{/* </>
												:<Empty style={{ width: '100%', height: '100%', minHeight:'600px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
											} */}
															{/* </div> */}
															{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
														</Spin>
													</Col>
													:	
													graph =='filteredSignalDAS' ? 
														<Col span={24} > 
															<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											
												
																{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
																{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
													<> */}
																<button
																	onClick={()=>{handlefullSize(fullsize === false ? true:false,'filteredSignalDAS');}} 
																	className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
																<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
																// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																	className="wellInfo" >
																	{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																	<b style={{fontSize : '12px'}}>Currently Viewing</b>
																	<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
																</p>
																<Plot
																	data={filteredSignalDASData}
																	layout={filteredSignalDAS}
																	style={{ width: '100%', height: '800px' }}
																	config={{
																		// displayModeBar: true,
																		displaylogo : false,
																		modeBarButtonsToRemove: [
																			'pan2d',
																			'zoom2d',
																			'select2d',
																			'zoomIn2d',
																			'zoomOut2d',
																			'autoScale2d',
																			'resetScale2d',
																			'hoverClosestCartesian',
																			'hoverCompareCartesian',
																			'zoom3d',
																			'pan3d',
																			'resetViews3d',
																			'hoverClosest3d',
																			'hoverCompare3d',
																			'orbitRotation',
																			'tableRotation',
																			'resetCameraDefault3d',
																			'resetCameraLastSave3d',
																			'lasso2d'
																		],
						
																	}}
																/>
																{/* </>

													:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
												} */}
															</Spin>
											
														</Col>
														:
														graph =='dtsTemperature' ? 
															<Col span={24}> 
																<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
											
																	{/* { dtsData && dtsData.length > 0 ?
														<> */}
																	<button
																		onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtsTemperature');}} 
																		className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
																	<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
																		// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																		className="wellInfo" >
																		{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																		<b style={{fontSize : '12px'}}>Currently Viewing</b>
																		<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name} <br />
																	</p>
																	<Plot
																		data={dtsData}
																		layout={dtsTemperature}
																		// config={{  }}
																		style={{ width: '100%', height: '800px' }}
																		config={{
																			// displayModeBar: true,
																			responsive: true,
																			displaylogo : false,
																			modeBarButtonsToRemove: [
																				'pan2d',
																				'zoom2d',
																				'select2d',
																				'zoomIn2d',
																				'zoomOut2d',
																				'autoScale2d',
																				'resetScale2d',
																				'hoverClosestCartesian',
																				'hoverCompareCartesian',
																				'zoom3d',
																				'pan3d',
																				'resetViews3d',
																				'hoverClosest3d',
																				'hoverCompare3d',
																				'orbitRotation',
																				'tableRotation',
																				'resetCameraDefault3d',
																				'resetCameraLastSave3d',
																				'lasso2d'
																			],
						
																		}}
																	/>
														
																	{/* </>
														: 
										
														<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
													} */}
																</Spin>
															</Col>
															: graph =='dtslinelayout' ? 

																<Col span={24} > 
			
																	<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
																		{/* { dasData && dasData.length > 0 ? 
																		<> */}
																		<button
																			onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtslinelayout');}} 
																			className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
																		<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
																			// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																			className="wellInfo" >
																			{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																			<b style={{fontSize : '12px'}}>Currently Viewing</b>
																			<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name} <br />
																		</p>
																		<Plot
																			data={snRatioDTSData}
																			layout={dtslinelayout}
																			// config={{ responsive: true }}
																			style={{ width: '100%', height: '800px' }}
																			config={{
																			// displayModeBar: true,
																				responsive: true,
																				displaylogo : false,
																				modeBarButtonsToRemove: [
																					'pan2d',
																					'zoom2d',
																					'select2d',
																					'zoomIn2d',
																					'zoomOut2d',
																					'autoScale2d',
																					'resetScale2d',
																					'hoverClosestCartesian',
																					'hoverCompareCartesian',
																					'zoom3d',
																					'pan3d',
																					'resetViews3d',
																					'hoverClosest3d',
																					'hoverCompare3d',
																					'orbitRotation',
																					'tableRotation',
																					'resetCameraDefault3d',
																					'resetCameraLastSave3d',
																					'lasso2d'
																				],
									
																			}}
																		/>
																		{/* </>
															
																		: 
													
																		<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
																	} */}
																		{/* </div> */}
																	</Spin>
			
																</Col>
			
																: 
																null
							}


						</Row>
								
							
						
					</Col>
				</Row>
					
				{/* </Row> */}

				<Modal
					title={langdata && langdata.GraphConfigurations ? langdata.GraphConfigurations: 'Graph Configurations' }
					open={isModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems: 'center',
								marginTop : '10px'
							}}
							>
								<Col span={8}>
									{/* <Row>
										<Space>
											<Button onClick={handleReset}>RESET</Button>
										</Space>
									</Row> */}
								</Col>
								<Col span={16}>
									<Row justify="end">
										<Col>
											{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
											<Space>
												<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
											</Space>
											<Space>&nbsp;</Space>
											<Space>
												<Button onClick={formik.handleSubmit} type="primary" style={{ margin : '5px'}} >{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
											</Space>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					]}
					width={900}
				>		
					<Divider/>
					<Row gutter={24}>
						<Col span={23}>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS:'DAS'}</b></Typography>
								: 
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
							}
						</Col>
						<Col  justify="end"  span={1}>
							<Tooltip placement="bottom" title={langdata && langdata.Reset ? langdata.Reset : 'Reset'}>
								<Avatar size="small" style={{ backgroundColor : '#1677FF'}}>							
									<ReloadOutlined onClick={handleReset} />	
								</Avatar>
							</Tooltip>
						</Col>
					</Row>
					{dasEnabled == true ? 
						<>
							<Row gutter={24} >
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.AmplitudeV ? langdata.AmplitudeV: 'Amplitude (V)'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpThreshold', value[0]);formik.setFieldValue('maxAmpThreshold', value[1]);}} 
										marks={ampMarks} 
										disabled = {formik.values.ampThresholdsOverride == true ? false : true}
										value={[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold, formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold]}/>
								</Col>
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.SNRatio ? langdata.SNRatio: 'S/N Ratio'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpSNThresholds', value[0]);formik.setFieldValue('maxAmpSNThresholds', value[1]);}} 
										marks={dasSNRatioMarks}
										disabled = {formik.values.ampSNThresholdsOverride == true ? false : true}
										value={[formik.values.ampSNThresholdsOverride == true ? formik.values.minAmpSNThresholds : formik.values.defaultminAmpSNThresholds, formik.values.ampSNThresholdsOverride == true ? formik.values.maxAmpSNThresholds : formik.values.defaultmaxAmpSNThresholds]}/>
								</Col>
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpFreqThresholds', value[0]);formik.setFieldValue('maxAmpFreqThresholds', value[1]);}} 
										marks={dasFrequencyMarks}
										disabled = {formik.values.ampFreqThresholdsOverride == true ? false : true}
										value={[formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds, formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds]}/>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip  placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampThresholdsOverride', value);}}
													checked={formik.values.ampThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampSNThresholdsOverride', value);}}
													checked={formik.values.ampSNThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampFreqThresholdsOverride', value);}}
													checked={formik.values.ampFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
							</Row>
							<Divider/>
						</>
						: null }
					{dtsEnabled == true ?
						<>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
								: null }
							<Row gutter={24} >
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.TemperatureC ? langdata.TemperatureC: 'Temperature (°C)'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempThreshold', value[0]);formik.setFieldValue('maxTempThreshold', value[1]);}} 
										marks={tempMarks}
										disabled = {formik.values.tempThresholdsOverride == true ? false : true}
										value={[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold, formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold]}
									/>
								</Col>
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.SNRatio ? langdata.SNRatio:  'S/N Ratio'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000}  
										onChange={(value) => {formik.setFieldValue('minTempSNThresholds', value[0]);formik.setFieldValue('maxTempSNThresholds', value[1]);}} 
										marks={dtsSNRatioMarks}
										disabled = {formik.values.tempSNThresholdsOverride == true ? false : true}
										value={[formik.values.tempSNThresholdsOverride == true ? formik.values.minTempSNThresholds : formik.values.defaultminTempSNThresholds, formik.values.tempSNThresholdsOverride == true ? formik.values.maxTempSNThresholds : formik.values.defaultmaxTempSNThresholds]}
									/>
								</Col>
								<Col span={8}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempFreqThresholds', value[0]);formik.setFieldValue('maxTempFreqThresholds', value[1]);}} 
										marks={dtsFrequencyMarks}
										disabled = {formik.values.tempFreqThresholdsOverride == true ? false : true}
										value={[formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds, formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds]}
									/>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('tempThresholdsOverride', value);}}
													checked={formik.values.tempThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempSNThresholdsOverride', value);}}
													checked={formik.values.tempSNThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={8}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempFreqThresholdsOverride', value);}}
													checked={formik.values.tempFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
							</Row>
							<Divider/>
						</>
						: null }
					<Row gutter={24} >
						<Col span={24}>
							<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Depth ? langdata.Depth +' (m)' : 'Depth (m)'}</Typography>
							<Slider style={{marginTop:'20px'}} range max={wellFrom ? wellFrom : tempendDepth} onChange={handleDepthChange} marks={depthMarks} value={[drawerDepthFrom ? drawerDepthFrom : depthRangeFrom, drawerDepthTo ? drawerDepthTo : depthRangeTo]} />
						</Col>
					</Row>
				</Modal>
			</Content>

			<Spin fullscreen spinning={false} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}