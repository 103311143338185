import React, { useEffect, useReducer, useState } from 'react';

import { Content } from 'antd/es/layout/layout';
import { Button, Col, Empty, Form, Input, message, Modal, Row, Space, Spin, Table, Typography } from 'antd';
import { ToolOutlined, PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useFormik } from 'formik';
import axios from 'axios';
import seismicTypeReducer from './reducer';
import { WELL_SEISMIC_TYPE_LIST, WELL_SEISMIC_TYPE_LIST_ERROR, WELL_SEISMIC_TYPE_LIST_SUCCESS, WELL_SEISMIC_TYPE_UPDATE_DATA } from './constants';

const { Title } = Typography;

export default function SeismicType() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const initalState = {wellSeisemicType : [], loading: false, error: false, updateData : {}};
	const [data, dispatch] = useReducer(seismicTypeReducer, initalState);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [updateId, setUpdateId] = useState();
	const [deleteId, setDeleteId] = useState();
		
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		loadSeismicType();
	},[]);

	const loadSeismicType = () => {
		dispatch({ type : WELL_SEISMIC_TYPE_LIST });
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellseismictypes?$sort[id]=1`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				Authorization:`Bearer ${authtoken}`	
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200){
					dispatch({ type : WELL_SEISMIC_TYPE_LIST_SUCCESS, payload : response.data.data });
				} else {
					dispatch({ type : WELL_SEISMIC_TYPE_LIST_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : WELL_SEISMIC_TYPE_LIST_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const column = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<a title={langdata && langdata.Editwellseismictype ? langdata.Editwellseismictype : 'Edit well seismic type'} onClick={() => {handleEditModal(record);}} ><EditOutlined/></a>
					<a title={langdata && langdata.Deletewellseismictype ? langdata.Deletewellseismictype : 'Delete well seismic type'} onClick={() => {handleDeleteModal(record);}} ><DeleteOutlined /></a>
				</Space>
			)
		}
	];

	const handleAddModal = () => {
		setShowAddModal(true);
	};

	const closeModal = () => {
		setShowAddModal(false);
		setShowEditModal(false);
		setShowDeleteModal(false);
	};

	const handleEditModal = (value) => {
		setShowEditModal(true);
		setUpdateId(value.id);
		dispatch({ type : WELL_SEISMIC_TYPE_UPDATE_DATA, payload : value});
	};

	const handleDeleteModal = (value) => {
		setShowDeleteModal(true);
		setDeleteId(value.id);
	};

	const addFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: null,
		},
		onSubmit: (values) => {
			const {name} = values;
			if( name == null ){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			let data = JSON.stringify({
				'name': name,
				'pipelineId': parseInt(pipelineId)
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/wellseismictypes`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						Modal.success({ title: langdata && langdata.Wellseismictypeaddedsuccessfully ? langdata.Wellseismictypeaddedsuccessfully : 'Well seismic type added successfully.' });	
						loadSeismicType();
						addFormik.resetForm();
					} else {
						Modal.warning({ title: langdata && langdata.Wellseismictypenotadded ? langdata.Wellseismictypenotadded : 'Well seismic type not added.' });					
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellseismictypenotadded ? langdata.Wellseismictypenotadded : 'Well seismic type not added.' });					
					}
				});
			setShowAddModal(false);				
		}
	});

	const editFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: data.updateData.name,
		},
		onSubmit: (values) => {
			const {name} = values;
			if( name == null || name == ''){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			let data = JSON.stringify({
				'name': name,
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellseismictypes/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						Modal.success({ title: langdata && langdata.Wellseismictypeupdatedsuccessfully ? langdata.Wellseismictypeupdatedsuccessfully : 'Well seismic type updated successfully.' });					
						loadSeismicType();
					} else {
						Modal.warning({ title: langdata && langdata.Wellseismictypenotupdated ? langdata.Wellseismictypenotupdated : 'Well seismic type not updated.' });					
					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellseismictypenotupdated ? langdata.Wellseismictypenotupdated : 'Well seismic type not updated.' });					
					}
				});
			setShowEditModal(false);
		}
	});

	const handleDelete = () => {

		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/wellseismictypes/${deleteId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				Authorization:`Bearer ${authtoken}`				},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 || response.status == 201){
					loadSeismicType();
					Modal.success({ title: langdata && langdata.Wellseismictypedeletedsuccessfully ? langdata.Wellseismictypedeletedsuccessfully : 'Well seismic type deleted successfully.' });					
				} else {
					Modal.warning({ title: langdata && langdata.Wellzonenotdeleted ? langdata.Wellzonenotdeleted : 'Well seismic type not deleted' });					
				}
			})
			.catch((error) => {
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				} else {
					Modal.warning({ title: langdata && langdata.Wellzonenotdeleted ? langdata.Wellzonenotdeleted : 'Well seismic type not deleted' });
				}
			});
		setShowDeleteModal(false);
	};
	
	return (
		<Content>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col span={8}>
					<Row>
						<Space>
							<ToolOutlined style={{fontSize:'30px'}}/>
						</Space>
						<Space>&nbsp;&nbsp;</Space>
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.WellSeismicTypes ? langdata.WellSeismicTypes : 'Well Seismic Types'}</Title>
					</Row>
				</Col>
				<Col span={16}>
					<Row justify="end">
						<Col>
							{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
							<Space>
								<Space>&nbsp;</Space>
								<Button style={{margin:'5px'}} type="primary" onClick={handleAddModal}><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
							</Space>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ data && data.wellSeisemicType.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}}
							columns = { column } 
							dataSource = { data.wellSeisemicType } 
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>
			
			<Modal
				title={langdata && langdata.AddWellSeismicTypes ? langdata.AddWellSeismicTypes : 'Add Well Seismic Types'}
				centered
				open={showAddModal}
				onOk={{closeModal}}
				onCancel={closeModal}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={addFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" onSubmit={addFormik.handleSubmit}>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item 
								label={langdata && langdata.SeismicTypeName ? langdata.SeismicTypeName : 'Seismic Type Name'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheseismictypename ? langdata.Pleaseentertheseismictypename : 'Please enter the seismic type name' }]} required>
								<Input 
									name="name" 
									onChange={addFormik.handleChange} 
									placeholder={langdata && langdata.Pleaseentertheseismictypename ? langdata.Pleaseentertheseismictypename : 'Please enter the seismic type name'} 
									value={addFormik.values.name} 
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>


			<Modal
				title={langdata && langdata.Editwellseismictype ? langdata.Editwellseismictype : 'Edit Well Seismic Types'}
				centered
				open={showEditModal}
				onOk={{closeModal}}
				onCancel={closeModal}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeModal}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary" onClick={editFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={500}
			>
				<Form layout="vertical" onSubmit={editFormik.handleSubmit}>
					<Row gutter={24}>
						<Col span={24}>
							<Form.Item 
								label={langdata && langdata.SeismicTypeName ? langdata.SeismicTypeName : 'Seismic Type Name'} 
								rules={[{ required: true, message: langdata && langdata.Pleaseentertheseismictypename ? langdata.Pleaseentertheseismictypename : 'Please enter the seismic type name' }]} required>
								<Input 
									name="name" 
									onChange={editFormik.handleChange} 
									placeholder={langdata && langdata.Pleaseentertheseismictypename ? langdata.Pleaseentertheseismictypename : 'Please enter the seismic type name'} 
									value={editFormik.values.name} 
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteModal} onOk={closeModal} onCancel={closeModal}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={closeModal}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{ langdata && langdata.Areyousureyouwanttodeletethisseismictype ? langdata.Areyousureyouwanttodeletethisseismictype :'Are you sure you want to delete this seismic type?'}</Col>
				</Row>
			</Modal>


			<Spin fullscreen spinning={false} />
		</Content>
	);
}