import React from 'react';
import { useAuth } from '../Context';

import { Row, Col, Typography, Space,  Popover, Avatar, Card } from 'antd';
import { Content } from 'antd/es/layout/layout';
import {  InfoOutlined,AreaChartOutlined } from '@ant-design/icons';

// import Plot from 'react-plotly.js';

import englan from '../../english.json';
import germanlan from '../../german.json';
import arabiclan from '../../arabic.json';
import frenchlan from '../../french.json';
import hindilan from '../../hindi.json';
import malayalamlan from '../../malayalam.json';
import reportImg from '../../images/visualization/reports.gif';
import fractureReportImg from '../../images/visualization/fractureMonitoring.gif';
// import sandingressReportImg from '../../images/visualization/sandingress.gif';
import wellimg from '../../images/visualization/well-leak-detect.gif';
// import heatmap from '../../images/visualization/heatmap.gif';
import leakdetectReportImg from '../../images/visualization/leak.gif';
import { useNavigate } from 'react-router-dom';
import AmplitudeImg from '../../images/visualization/amplitude.gif';
import flowImg from '../../images/visualization/flowmonitoring.gif';

const { Title } = Typography;

// const content = <div style={contentStyle} />;

export default function WellVisualization() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	// const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	
	useAuth();	

	const navigate = useNavigate();

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;	

	const infoContent = (
		<Card size="small" bordered="false" type="inner" style={{ width: 300 }}> 
			<p>{langdata && langdata.VulnerablePointsContent1 ? langdata.VulnerablePointsContent1 : 'Chainages that are potentially insecure or vulnerable to attack can be marked as vulnerable chainages and will be listed in this module.'}</p>
		</Card>
	);
	const featuresModule = JSON.parse(localStorage.getItem(`${dashboardtype.toUpperCase()}_FEATURES_MODULE_DASHBOARD`));	


	let arr = [];

	for(let i=0; i<=350; ++i){
		arr.push(i);
	}

	const handleNaviagte = (e) => {
		if(e=='wellreports'){
			navigate(( '/report-well-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
		}
		else if (e=='sandingress'){
			navigate(( '/sandingress-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}
		else if (e=='leakvisualization'){
			navigate(( '/leak-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}

		
		else if (e=='fractureMonitoring'){
			navigate(( '/fracture-monitoring-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}

		else if (e=='flowmonitoring'){
			navigate(( '/flow-monitoring'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}
		else if (e=='microseismic'){
			navigate(( '/microseismic-monitoring-visualization'),
				{ state: 
					{ 
						// data: {params:'', value:4, regionId : regionId}, 
						path: 'well-visualization' } 
				});
			localStorage.setItem(dashboardtype.toUpperCase()+ '_ACTIVE_MENU_ITEM' , '116');
			
		}


		
		
	};

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.WellVisualization ? langdata.WellVisualization : 'Well Visualization'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								
								<Space>&nbsp;&nbsp;</Space>
								<Popover title={langdata && langdata.WellVisualization ? langdata.WellVisualization : 'Well Visualizations'} content={infoContent} trigger="click">
									<Avatar size="small"><InfoOutlined /></Avatar>
								</Popover>
							</Col>
						</Row>
					</Col>
				</Row>

				<Row gutter={{
					xs: 8,
					sm: 16,
					md: 24,
					lg: 32,
				}} style={{
					marginTop:'20px',
					marginLeft:'0px',
					marginRight:'0px',
					marginBottom: '20px',
				}}>
					{featuresModule.wellFlowMonitoringEnabled ==true ?
						<Space>
							<Col onClick={()=>{handleNaviagte('flowmonitoring');}}  style={{marginBottom:'20px'}} span={12}> 
								<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={flowImg} alt='event image' style={{ width : '450px', height : '190px' }}/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
											<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.FlowMonitoring: 'Flow Monitoring'}</Typography>
										</Col>
										{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
									</Card.Grid>
								</Card>
							</Col>
						</Space>:null}

					{featuresModule.wellSandIngressEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('sandingress');}}  style={{marginBottom:'20px'}} span={12}> 
								<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={leakdetectReportImg} alt='event image' style={{ width : '450px', height : '190px' }}/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
											<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.SandIngress: 'Sand Ingress'}</Typography>
										</Col>
										{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
									</Card.Grid>
								</Card>
							</Col>
						</Space> :null}

					{featuresModule.wellLeakDetectionEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('leakvisualization');}}  style={{marginBottom:'20px'}} span={12}> 
								<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={wellimg} alt='event image' style={{ width : '450px', height : '190px' }}/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
											<Typography style={{fontWeight:'bold'}}>{langdata && langdata ? langdata.LeakDetection: 'Leak Detection'}</Typography>
										</Col>
										{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
									</Card.Grid>
								</Card>
							</Col>
						</Space>:null}

					{featuresModule.wellFracturingEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('fractureMonitoring');}}  style={{marginBottom:'20px'}} span={12}> 
								<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={fractureReportImg} alt='event image' style={{ width : '400px', height : '190px' }}/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center',textAlign : 'center' }}>
											<Typography style={{ fontWeight : 'bold'}}>{langdata && langdata ? langdata.FractureMonitoring: 'Fracture Monitoring'}</Typography>
										</Col>
										{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
									</Card.Grid>
								</Card>
							</Col>
						</Space> : null}

					{featuresModule.wellMicroSeismicEnabled ==true ?

						<Space>
							<Col onClick={()=>{handleNaviagte('microseismic');}}  style={{marginBottom:'20px'}} span={12}> 
								<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
									<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
										{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
										<Col style={{ display : 'flex', justifyContent : 'center' }}>
											<img shape="square" src={AmplitudeImg} alt='event image' 
												style={{ width : '400px', height : '190px' }}
											/>
										</Col>
										<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
											<Typography style={{ fontWeight : 'bold'}}>{langdata && langdata.MicroseismicMonitoring ? langdata.MicroseismicMonitoring : 'Microseismic Monitoring'}</Typography>
										</Col>
										{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
									</Card.Grid>
								</Card>
							</Col>
						</Space>:null}

					<Space>
						<Col onClick={()=>{handleNaviagte('wellreports');}}  style={{marginBottom:'20px'}} span={12}> 
							<Card style={{ minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px', cursor: 'pointer'}} >
								<Card.Grid style={{minWidth:'500px', minHeight:'250px', maxWidth:'500px', maxHeight:'250px',}}>
									{/* <Statistic
								title="EVENTS"
								value={activityCount.totalEvents}
								precision={0}
								// valueStyle={{ color: 'green' }}
								prefix={<OneToOneOutlined />}
								suffix=""
								src={eventsImg}
							/> */}
									<Col style={{ display : 'flex', justifyContent : 'center' }}>
										<img shape="square" src={reportImg} alt='event image' 
											style={{ width : '400px', height : '190px' }}
										/>
									</Col>
									<Col style={{ display : 'flex', justifyContent : 'center', textAlign : 'center' }}>
										<Typography style={{ fontWeight : 'bold'}}>{langdata && langdata ? langdata.Reports: 'Reports'}</Typography>
									</Col>
									{/* <Col style={{ display : 'flex', justifyContent : 'center', marginTop : '10px' }}>
								<Typography style={{ fontSize : '25px'}}><b> </b> </Typography>
							</Col> */}
								</Card.Grid>
							</Card>
						</Col>
					</Space>

				
				</Row>
			</Content>

			

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}