import { Breadcrumb, Card, Col, DatePicker, message, Radio, Row,  Select,  Space, Spin, Tooltip, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useReducer, useState } from 'react';
import { AreaChartOutlined, HomeOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import dayjs from 'dayjs';
import moment from 'moment';
// import * as d3 from 'd3';
import Plot from 'react-plotly.js';
import reportWellReducer from './reducer';
import { DAS_GRAPH_AMP_DATA, DAS_GRAPH_AMP_DEPTH_DATA, DAS_GRAPH_AMP_ERROR, DAS_GRAPH_AMP_SUCCESS, DAS_GRAPH_AMP_TIME_DATA, DAS_GRAPH_DATA, DAS_GRAPH_DATA_ERROR, DAS_GRAPH_DATA_SUCCESS, DAS_GRAPH_TIME_DATA, DAS_GRAPH_TIME_ERROR, DAS_GRAPH_TIME_EVENT, DAS_GRAPH_TIME_SUCCESS, DTS_GRAPH_DATA, DTS_GRAPH_DATA_ERROR, DTS_GRAPH_DATA_SUCCESS, DTS_GRAPH_TEMP_DATA, DTS_GRAPH_TEMP_DEPTH_DATA, DTS_GRAPH_TEMP_ERROR, DTS_GRAPH_TEMP_SUCCESS, DTS_GRAPH_TEMP_TIME_DATA, DTS_GRAPH_TIME_DATA, DTS_GRAPH_TIME_ERROR, DTS_GRAPH_TIME_EVENT, DTS_GRAPH_TIME_SUCCESS } from './constants';
import { useAuth } from '../../Context';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const { Title } = Typography;
const { RangePicker } = DatePicker;

// const unpack = (rows, key) => rows.map(row => row[key]);

export default function ReportWellVisualization() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const [startDate, setStartDate] = useState(moment.utc(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss'));
	const [endDate, setEndDate] = useState(moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'));
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const initalState = {details: null, pending: false, error: false, das_data:null, das_time_data:null , dasTimeEventData:null, dtsTimeEventData:null, dts_data:null, dts_time_data:null, 
		das_amp_data:null, dasTimeAmpData:null, dasDepthAmpData:null, dts_temp_data:null, dtsTimeTempData:null, dtsDepthTempData:null};
	const [data, dispatch] = useReducer(reportWellReducer, initalState);
	const { state } = useLocation();
	const navigate = useNavigate();
	const [fullsize, setfullSize] = useState(false);
	const [graph, setGraph] = useState(null);

	const [wellId, setWellId] = useState();
	const [channelId, setChannelId] = useState(null);	

	let dtsEnabled1 =true;
	let dasEnabled1 =true;
	let tempdtsminth; 
	let tempdtsmaxth;
	// let tempdasminth;
	// let tempdasmaxth; 
	
	const [tempTh, setTempTH] =useState({'maxTH':null, 'minTH':null});
	// const [ampTh, setAmpTH] =useState({'maxTH':null, 'minTH':null});

	let tempWellId;
	let tempchannelId;

	const { wellinfo, getWellInfo, wellchanneldata, getWellChannels} = useAuth();
	
	const handleSetWell = (e,options) =>{		
		
		tempWellId=e;
		setWellId(e);
		setdtsEnabled(options.dtsEnabled);
		setdasEnabled(options.dasEnabled);

		setTempTH({'maxTH':options.dtsmaxth, 'minTH':options.dtsminth});
		// setAmpTH({'maxTH':options.dasmaxth, 'minTH':options.dasminth});
		
	};

	const welldata =[];

	wellinfo && wellinfo.map((item,index) => {
		welldata.push({
			value : item.id,
			label : item.name.toUpperCase(),
			
			dtsEnabled : item.dtsEnabled,
			dasEnabled : item.dasEnabled,
			dtsminth : item.minTempThreshold,
			dtsmaxth : item.maxTempThreshold,
			dasminth : item.minAmpThreshold,
			dasmaxth : item.maxAmpThreshold,
			
		});
		if (index === 0) {			
			tempWellId = item.id;
			dtsEnabled1 = item.dtsEnabled;
			dasEnabled1 = item.dasEnabled;
			tempdtsminth = item.minTempThreshold;
			tempdtsmaxth = item.maxTempThreshold;
			// tempdasminth = item.minAmpThreshold;
			// tempdasmaxth = item.maxAmpThreshold;
		}
	});

	const [dtsEnabled, setdtsEnabled] = useState();
	const [dasEnabled, setdasEnabled] = useState();

	useEffect(() => {
		setdtsEnabled(dtsEnabled1);
		setdasEnabled(dasEnabled1);
	},[dtsEnabled1, dasEnabled1]);

	const [viewType, setViewType] = useState( dasEnabled == false && dtsEnabled == true ? 'DTS' : 'DAS');
	
	const channellist =[{
		value:null,label:'ALL'
	}];

	wellchanneldata && wellchanneldata.map((item,index)=>{
		channellist.push({
			value: item.id,
			label : item.name

		});
		if(index==0){
			tempchannelId =  item.id;
		}
	});


	const getWellReportsdataDAS =()=>{
		dispatch({ type : DAS_GRAPH_DATA});
		
		let params='';
		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalydasreports-zone?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DAS_GRAPH_DATA_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DAS_GRAPH_DATA_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DAS_GRAPH_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};


	const getWellReportsTimedataDAS =()=>{
		dispatch({ type : DAS_GRAPH_TIME_DATA});

		let params='';
		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalydasreports-time?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DAS_GRAPH_TIME_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DAS_GRAPH_TIME_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DAS_GRAPH_TIME_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const getWellReportsTimedataDTS =()=>{
		dispatch({ type : DTS_GRAPH_TIME_DATA});

		let params='';
		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalydtsreports-time?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DTS_GRAPH_TIME_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DTS_GRAPH_TIME_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DTS_GRAPH_TIME_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};

	const getWellReportsdataDTS =()=>{
		dispatch({ type : DTS_GRAPH_DATA});

		let params='';
		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalydtsreports-zone?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DTS_GRAPH_DATA_SUCCESS, payload : response.data});
				}else{
					dispatch({ type : DTS_GRAPH_DATA_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DTS_GRAPH_DATA_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};


	const getWellAmplitudedataDAS =()=>{
		dispatch({ type : DAS_GRAPH_AMP_DATA});

		let params='';
		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellreportsdas?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DAS_GRAPH_AMP_SUCCESS, payload : response.data.data});
				}else{
					dispatch({ type : DAS_GRAPH_AMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DAS_GRAPH_AMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}

				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};


	const getWellTempdataDTS =()=>{
		dispatch({ type : DTS_GRAPH_TEMP_DATA});

		let params='';
		if(wellId || tempWellId){
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellreportsdts?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type : DTS_GRAPH_TEMP_SUCCESS, payload : response.data.data});
				}else{
					dispatch({ type : DTS_GRAPH_TEMP_ERROR});

				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : DTS_GRAPH_TEMP_ERROR});
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});

	};	

	useEffect(() => {
		getWellInfo(authtoken);

		getWellReportsdataDAS();
		getWellReportsTimedataDAS();

		getWellReportsdataDTS();
		getWellReportsTimedataDTS();

		getWellAmplitudedataDAS();
		getWellTempdataDTS();


		if(tempWellId || wellId){
			getWellChannels(authtoken, null,wellId ? wellId : tempWellId);
		}

	},[wellId,tempWellId, endDate, startDate, channelId, tempchannelId]);	

	

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [fullsize]);	

	
	useEffect(() => {

		getDasData();
		getDtsData();

		getDasAmpData();
		getDasAmpDepthData();

		getDtsTempData();
		getDtsTempDepthData();

	}, [tempTh,tempdtsmaxth,tempdtsminth,channelId,tempchannelId,wellId,tempWellId,data.das_data,data.dts_data]);


	
	// ********************* DTS LAYOUTS ******************************* //
	const [layoutDTS, setLayoutDTS] = useState({
		title: {
			text: langdata && langdata.TimeWiseAverageTemperature ? langdata.TimeWiseAverageTemperature : 'Time Wise Average Temperature',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
		},
		shapes : [],
		annotations: [], 
	});

	const [layoutDTSDepth, setLayoutDTSDepth] = useState({
		title: {
			text: langdata && langdata.DepthWiseAverageTemperature ? langdata.DepthWiseAverageTemperature : 'Depth Wise Average Temperature',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'linear',
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Temperature ? langdata.Temperature : 'Temperature',
		},
		shapes : [],
		annotations: [], 
	});

	const [layoutEventDTS, setLayoutEventDTS] = useState({
		title: {
			text: langdata && langdata.TimeWiseEventCount ? langdata.TimeWiseEventCount : 'Time Wise Event Count',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.EventCount ? langdata.EventCount : 'Event Count',
		},
		shapes : [],
		annotations: [], 
	});









	// ****************** DAS LAYOUT ******************** //

	const [layoutDAS, setLayoutDAS] = useState({
		title: {
			text: langdata && langdata.TimeWiseAverageAmplitude ? langdata.TimeWiseAverageAmplitude : 'Time Wise Average Amplitude',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
		},
		shapes : [],
		annotations: [], 
	});

	const [layoutDASDepth, setLayoutDASDepth] = useState({
		title: {
			text: langdata && langdata.DepthWiseAverageAmplitude ? langdata.DepthWiseAverageAmplitude : 'Depth Wise Average Amplitude',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'linear',
			title: langdata && langdata.Depth ? langdata.Depth : 'Depth',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.Amplitude ? langdata.Amplitude : 'Amplitude',
		},
		shapes : [],
		annotations: [], 
	});

	const [layoutEventDAS, setLayoutEventDAS] = useState({
		title: {
			text: langdata && langdata.TimeWiseEventCount ? langdata.TimeWiseEventCount : 'Time Wise Event Count',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			// range: ['2016-07-01', '2016-12-31'],
			type: 'date',
			title: langdata && langdata.Time ? langdata.Time : 'Time',
		},
		yaxis: {
			autorange: true,
			// range: [86.8700008333, 138.870004167],
			type: 'linear',
			title: langdata && langdata.EventCount ? langdata.EventCount : 'Event Count',
		},
		shapes : [],
		annotations: [], 
	});




	const handleSetDate = (e) =>{
		
		setStartDate(moment.utc(new Date(e[0] && e[0].$d)).format('YYYY-MM-DD HH:mm:ss'));
		setEndDate(moment.utc(new Date(e[1] && e[1].$d)).format('YYYY-MM-DD HH:mm:ss'));  
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};




	const getDasData = () =>{
		const timeeventcountdata = {
			type: 'scatter',
			mode: 'lines',
			x:  data && data.das_time_data && data.das_time_data && data.das_time_data.event_times ? data.das_time_data.event_times : [],
			y:  data && data.das_time_data && data.das_time_data && data.das_time_data.event_counts ? data.das_time_data.event_counts : [],
			// x: unpack(rows, 'Date'),
			// y: unpack(rows, 'AAPL.High'),
			line: { color: '#17BECF' }
		};		
	
		// const trace2 = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x: unpack(rows, 'Date'),
		// 	y: unpack(rows, 'AAPL.Low'),
		// 	line: { color: '#b32d1e' }
		// };

		// console.log('trace2',trace2);

		
				
		dispatch({type : DAS_GRAPH_TIME_EVENT, payload : timeeventcountdata});
		const xDates =data && data.das_time_data && data.das_time_data && data.das_time_data.event_times ? data.das_time_data.event_times : [];
		const xMin = xDates[0];
		const xMax = xDates[xDates.length - 1];
		const horizontalLines = [
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				y1: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				line: {
					color: '#14877c',
					width: 2,
					dash: 'dashdot', // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				y1: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				line: {
					color: '#14877c',
					width: 2,
					dash: 'dot',     // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
		];
		const annotations = [
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.maxTH ? tempTh.maxTH :tempdtsmaxth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.minTH ? tempTh.minTH :tempdtsminth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
		];
		// setDataDTS([trace2]);
		// setLayoutDAS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		setLayoutEventDAS(prevLayout => ({
			...prevLayout,
			shapes: horizontalLines, // Update the shapes array with two horizontal lines
			annotations: annotations,
		}));
		// setLayoutDASDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEventDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTSDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
	};



	const getDtsData = () =>{
		const timeeventcountdata = {
			type: 'scatter',
			mode: 'lines',
			x:  data && data.dts_time_data && data.dts_time_data && data.dts_time_data.event_times ? data.dts_time_data.event_times : [],
			y:  data && data.dts_time_data && data.dts_time_data && data.dts_time_data.event_counts ? data.dts_time_data.event_counts : [],
			// x: unpack(rows, 'Date'),
			// y: unpack(rows, 'AAPL.High'),
			line: { color: '#B32D1E' }
		};				
	
		// const trace2 = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x: unpack(rows, 'Date'),
		// 	y: unpack(rows, 'AAPL.Low'),
		// 	line: { color: '#b32d1e' }
		// };

		// console.log('trace2',trace2);

		
				
		dispatch({type : DTS_GRAPH_TIME_EVENT, payload : timeeventcountdata});
		const xDates =data && data.dts_time_data && data.dts_time_data && data.dts_time_data.event_times ? data.dts_time_data.event_times : [];
		const xMin = xDates[0];
		const xMax = xDates[xDates.length - 1];
		const horizontalLines = [
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				y1: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				line: {
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash: 'dashdot', // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				y1: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				line: {
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash: 'dot',     // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
		];
		const annotations = [
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.maxTH ? tempTh.maxTH :tempdtsmaxth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.minTH ? tempTh.minTH :tempdtsminth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
		];
		// setDataDTS([trace2]);
		// setLayoutDAS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		setLayoutEventDTS(prevLayout => ({
			...prevLayout,
			shapes: horizontalLines, // Update the shapes array with two horizontal lines
			annotations: annotations,
		}));
		// setLayoutDASDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEventDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTSDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
	};

	// console.log(data);
	

	const getDasAmpData = () =>{		

		const amptimedata = {
			type: 'scatter',
			mode: 'lines',
			x:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.datetime ? data.das_amp_data.datetime : [],
			y:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.amplitudeTimeReports ? data.das_amp_data.amplitudeTimeReports : [],
			// x: unpack(rows, 'Date'),
			// y: unpack(rows, 'AAPL.High'),
			line: { color: '#17BECF' }
		};			
		
				
		dispatch({type : DAS_GRAPH_AMP_TIME_DATA, payload : amptimedata});
		// dispatch({type : DAS_GRAPH_AMP_DEPTH_DATA, payload: ampdepthdata});

		const xDates =data && data.das_amp_data && data.das_amp_data.datetime ? data.das_amp_data.datetime : [];
		const xMin = xDates[0];
		const xMax = xDates[xDates.length - 1];
		
		const horizontalLines = [
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				y1: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				line: {
					color: '#14877c',
					width: 2,
					dash: 'dashdot', // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				y1: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				line: {
					color: '#14877c',
					width: 2,
					dash: 'dot',     // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
		];
		const annotations = [
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.maxTH ? tempTh.maxTH :tempdtsmaxth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.minTH ? tempTh.minTH :tempdtsminth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
		];
		// setDataDTS([trace2]);
		setLayoutDAS(prevLayout => ({
			...prevLayout,
			shapes: horizontalLines, // Update the shapes array with two horizontal lines
			annotations: annotations,
		}));
		// setLayoutDTS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEvent(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDASDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEventDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTSDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
	};
	

	const getDasAmpDepthData = () =>{		
		// const amptimedata = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.datetime ? data.das_amp_data.datetime : [],
		// 	y:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.amplitudeTimeReports ? data.das_amp_data.amplitudeTimeReports : [],
		// 	// x: unpack(rows, 'Date'),
		// 	// y: unpack(rows, 'AAPL.High'),
		// 	line: { color: '#17BECF' }
		// };		
		
		const ampdepthdata = {
			type: 'scatter',
			mode: 'lines',
			x:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.depth_arrays && data.das_amp_data.depth_arrays ? data.das_amp_data.depth_arrays: [],
			y:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.avg_depth_amps && data.das_amp_data.avg_depth_amps ? data.das_amp_data.avg_depth_amps: [],
			// x: unpack(rows, 'Date'),
			// y: unpack(rows, 'AAPL.High'),
			line: { color: '#17BECF' }
		};
	

		// console.log('trace2',trace2)		

		
				
		// dispatch({type : DAS_GRAPH_AMP_TIME_DATA, payload : amptimedata});
		
		dispatch({type : DAS_GRAPH_AMP_DEPTH_DATA, payload: ampdepthdata});

		const xDates =data && data.das_amp_data && data.das_amp_data && data.das_amp_data.depth_arrays && data.das_amp_data.depth_arrays ? data.das_amp_data.depth_arrays : [];
		
		const xMin = xDates[0];
		const xMax = xDates[xDates.length - 1];
		
		const horizontalLines = [
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				y1: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				line: {
					color: '#14877c',
					width: 2,
					dash: 'dashdot', // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				y1: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				line: {
					color: '#14877c',
					width: 2,
					dash: 'dot',     // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
		];
		const annotations = [
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.maxTH ? tempTh.maxTH :tempdtsmaxth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.minTH ? tempTh.minTH :tempdtsminth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
		];
		// setDataDTS([trace2]);
		// setLayoutDAS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEvent(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		setLayoutDASDepth(prevLayout => ({
			...prevLayout,
			shapes: horizontalLines, // Update the shapes array with two horizontal lines
			annotations: annotations,
		}));
		// setLayoutEventDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTSDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
	};


	const getDtsTempData = () =>{
		const amptimedata = {
			type: 'scatter',
			mode: 'lines',
			x:  data && data.dts_temp_data && data.dts_temp_data && data.dts_temp_data.datetime ? data.dts_temp_data.datetime : [],
			y:  data && data.dts_temp_data && data.dts_temp_data && data.dts_temp_data.amplitudeTimeReports ? data.dts_temp_data.amplitudeTimeReports : [],
			// x: unpack(rows, 'Date'),
			// y: unpack(rows, 'AAPL.High'),
			line: { color: '#B32D1E' }
		};		
		
		// const ampdepthdata = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.depth_arrays && data.das_amp_data.depth_arrays[0] ? data.das_amp_data.depth_arrays[0] : [],
		// 	y:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.avg_depth_amps && data.das_amp_data.avg_depth_amps[0] ? data.das_amp_data.avg_depth_amps[0] : [],
		// 	// x: unpack(rows, 'Date'),
		// 	// y: unpack(rows, 'AAPL.High'),
		// 	line: { color: '#17BECF' }
		// };
	
		// const trace2 = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x: unpack(rows, 'Date'),
		// 	y: unpack(rows, 'AAPL.Low'),
		// 	line: { color: '#b32d1e' }
		// };

		// console.log('trace2',trace2)		

		
				
		dispatch({type : DTS_GRAPH_TEMP_TIME_DATA, payload : amptimedata});
		// dispatch({type : DAS_GRAPH_AMP_DEPTH_DATA, payload: ampdepthdata});

		const xDates =data && data.dts_temp_data && data.dts_temp_data.datetime ? data.dts_temp_data.datetime : [];
		const xMin = xDates[0];
		const xMax = xDates[xDates.length - 1];
		const horizontalLines = [
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				y1: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				line: {
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash: 'dashdot', // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				y1: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				line: {
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash: 'dot',     // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
		];
		const annotations = [
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.maxTH ? tempTh.maxTH :tempdtsmaxth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.minTH ? tempTh.minTH :tempdtsminth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
		];
		// setDataDTS([trace2]);
		// setLayoutDAS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		setLayoutDTS(prevLayout => ({
			...prevLayout,
			shapes: horizontalLines, // Update the shapes array with two horizontal lines
			annotations: annotations,
		}));
		// setLayoutEvent(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDASDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEventDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTSDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
	};


	const getDtsTempDepthData = () =>{
		// const amptimedata = {
		// 	type: 'scatter',
		// 	mode: 'lines',
		// 	x:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.datetime ? data.das_amp_data.datetime : [],
		// 	y:  data && data.das_amp_data && data.das_amp_data && data.das_amp_data.amplitudeTimeReports ? data.das_amp_data.amplitudeTimeReports : [],
		// 	// x: unpack(rows, 'Date'),
		// 	// y: unpack(rows, 'AAPL.High'),
		// 	line: { color: '#17BECF' }
		// };		
		
		const ampdepthdata = {
			type: 'scatter',
			mode: 'lines',
			x:  data && data.dts_temp_data && data.dts_temp_data && data.dts_temp_data.depth_arrays && data.dts_temp_data.depth_arrays ? data.dts_temp_data.depth_arrays : [],
			y:  data && data.dts_temp_data && data.dts_temp_data && data.dts_temp_data.avg_depth_temps && data.dts_temp_data.avg_depth_temps ? data.dts_temp_data.avg_depth_temps : [],
			// x: unpack(rows, 'Date'),
			// y: unpack(rows, 'AAPL.High'),
			line: { color: '#B32D1E' }
		};
	

		// console.log('trace2',trace2)		

		
				
		// dispatch({type : DAS_GRAPH_AMP_TIME_DATA, payload : amptimedata});
		dispatch({type : DTS_GRAPH_TEMP_DEPTH_DATA, payload: ampdepthdata});

		const xDates =data && data.dts_temp_data && data.dts_temp_data && data.dts_temp_data.depth_arrays && data.dts_temp_data.depth_arrays ? data.dts_temp_data.depth_arrays : [];
		const xMin = xDates[0];
		const xMax = xDates[xDates.length - 1];
		const horizontalLines = [
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				y1: tempTh.maxTH ? parseInt(tempTh.maxTH) : parseInt(tempdtsmaxth),           // Y-coordinate for the first horizontal line
				line: {
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash: 'dashdot', // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
			{
				type: 'line',
				x0: xMin,
				x1: xMax,
				y0: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				y1: tempTh.minTH ? tempTh.minTH :tempdtsminth,           // Y-coordinate for the second horizontal line
				line: {
					color: 'rgb(255, 0, 0)',
					width: 2,
					dash: 'dot',     // Optional: can use 'solid', 'dot', 'dash', 'dashdot'
				},
			},
		];
		const annotations = [
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.maxTH ? tempTh.maxTH :tempdtsmaxth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MaxThreshold ? langdata.MaxThreshold : 'Max Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
			{
				x: xMax, // Position annotation at the end of the x-axis range
				y: tempTh.minTH ? tempTh.minTH :tempdtsminth,  // Y-coordinate for the annotation
				xanchor: 'right',
				yanchor: 'top',
				text: langdata && langdata.MinThreshold ? langdata.MinThreshold : 'Min Threshold', // Text for the annotation
				showarrow: true,
				arrowhead: 2,
				ax: -10, // X-offset for the annotation
				ay: 0,   // Y-offset for the annotation
				font: {
					color: 'red',
					size: 12,
				},
			},
		];
		// setDataDTS([trace2]);
		// setLayoutDAS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDTS(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEvent(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutDASDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		// setLayoutEventDepth(prevLayout => ({
		// 	...prevLayout,
		// 	shapes: horizontalLines, // Update the shapes array with two horizontal lines
		// 	annotations: annotations,
		// }));
		setLayoutDTSDepth(prevLayout => ({
			...prevLayout,
			shapes: horizontalLines, // Update the shapes array with two horizontal lines
			annotations: annotations,
		}));
	};



	

	const barDASData = [
		{
			x:  data && data.das_data && data.das_data && data.das_data.zones ? data.das_data.zones : [],
			y:  data && data.das_data && data.das_data && data.das_data.event_counts ? data.das_data.event_counts : [],
			name: 'Event 1',
			type: 'bar',
			marker:{
				color:'#17BECF'
			}
		},
		// {
		// 	x: Array.from({ length: 20 }, (_, i) => `Zone ${i + 1}`),
		// 	y: Array.from({ length: 20 }, () => Math.floor(Math.random() * 30) + 10),
		// 	name: 'Event 1',
		// 	type: 'bar',
		// },
		
	];
	const barDTSData = [
		{
			x:  data && data.dts_data && data.dts_data && data.dts_data.zones ? data.dts_data.zones : [],
			y:  data && data.dts_data && data.dts_data && data.dts_data.event_counts ? data.dts_data.event_counts : [],
			name: 'Event 1',
			type: 'bar',
			marker:{
				color:'#B32D1E'
			}
		},
		// {
		// 	x: Array.from({ length: 20 }, (_, i) => `Zone ${i + 1}`),
		// 	y: Array.from({ length: 20 }, () => Math.floor(Math.random() * 30) + 10),
		// 	name: 'Event 1',
		// 	type: 'bar',
		// },
		
	];

	const  barDASLayout = {
		barmode: 'stack',
		title: {
			text: langdata && langdata.ZoneWiseEventsCount ? langdata.ZoneWiseEventsCount :  'Zone Wise Events Count',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			title: langdata && langdata.Zone ? langdata.Zone : 'Zone',
			type: 'category',
		},
		yaxis: {
			title: langdata && langdata.EventCount ? langdata.EventCount : 'Event Count',
		}
	};


	const handlefullSize = (size,graph) =>{
		setfullSize(size);
		setGraph(graph);
	};	
	
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const disabledRangeTime = (currentDate) => {
		if (!currentDate) {
			return {};
		}
		
		const now = moment();
		
		// Disable future time slots on the current date
		if (currentDate.isSame(now, 'day')) {
			return {
				disabledHours: () => {
					const currentHour = now.hour();
					// Disable hours after the current hour
					return Array.from({ length: 24 }, (_, i) => (i > currentHour ? i : null)).filter(i => i !== null);
				},
				// disabledMinutes: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	// Disable minutes after the current minute in the current hour
				// 	if (currentHour === now.hour()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentMinute ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
				// disabledSeconds: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	const currentSecond = now.second();
				// 	// Disable seconds after the current second in the current minute
				// 	if (currentHour === now.hour() && currentMinute === now.minute()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentSecond ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
			};
		}
		
		// For future dates, allow all hours, minutes, and seconds
		return {};
	};

	const handleSetChannel = (value) => {
		setChannelId(value);
	};	
	

	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.Reports ? langdata.Reports : 'Reports'}</Title>
							<Space>&nbsp;&nbsp;</Space>
							{fullsize==false && dasEnabled == true && dtsEnabled == true ?
								<Space>
									<Radio.Group style={{margin:'5px'}} value={viewType} onChange={(e) => setViewType(e.target.value)}>
										<Radio.Button value="DAS">{langdata && langdata.DAS ? langdata.DAS : 'DAS'}</Radio.Button>
										<Radio.Button value="DTS">{langdata && langdata.DTS ? langdata.DTS : 'DTS'}</Radio.Button>
									</Radio.Group>
								</Space>
								: null }
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId ? wellId : tempWellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
									<Space>
										<Select
											style={{ width: '200px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectChannel ? langdata.SelectChannel : 'Select Channel'}
											optionFilterProp="children"
											defaultValuevalue={null}
											value={channelId ?  channelId : tempchannelId}											
											options={channellist}
											onSelect={(value)=>handleSetChannel(value)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
									<Space>
										<RangePicker 
											showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
											style={{ width: '350px', margin:'5px'}}
											// format="YYYY-MM-DD HH"
											defaultValue={[dayjs(new Date(new Date().getFullYear(), new Date().getMonth(),  new Date().getDate())),dayjs(new Date())]}
											format={settingsdateformat + ' ' + settingstimeformat}
											placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
											disabledDate={disabledDate}
											disabledTime={disabledRangeTime}
											onChange={handleSetDate}
											allowClear={false} />
										{/* <DatePicker allowClear={false} placeholder="Today" onChange={handleSetDate} style={{ width: '200px', margin:'5px'}} format={settingsdateformat} disabledDate={disabledDate} defaultValue={dayjs(moment().format('YYYY-MM-DD'), dateFormat)}/> */}
									</Space>
								</Tooltip>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					margin: '10px',
					// marginTop : '5px',
					// paddingLeft: '10px',
					// paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
				}} >
					<Breadcrumb
						items={[
							{
								href: '/dashboard',
								title: <HomeOutlined />,
							},
							{
								title: ( state.path && state.path === 'well-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
							},
							{
								title: `${langdata && langdata.Reports ? langdata.Reports:'Reports'}`,
							},
						]}
						// style={{ marginBottom : '10px'}}
					/>
				</Row>
				{fullsize==false ? 
					<>
						{viewType == 'DAS' ? 
							<>
								<Row style={{
									margin: '10px',
									marginTop : '10px',
									// paddingLeft: '10px',
									// paddingRight: '10px',
									// backgroundColor: 'red',
									alignItems:'center',
								}} >
									<Col span={12} style={{ minHeight : '450px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageAmplitude');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={[data.dasTimeAmpData]}
												layout={layoutDAS}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>
									</Col>
									<Col span={12} style={{ minHeight : '450px', paddingLeft : '5px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageAmplitudeDepth');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={[data.dasDepthAmpData]}
												layout={layoutDASDepth}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>
									</Col>
									{/* <Space>&nbsp;</Space> */}
									<Col span={12} style={{minHeight : '450px', marginTop : '15px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'eventCount');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={[data.dasTimeEventData]}
												layout={layoutEventDAS}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>
									</Col>
									<Col span={12} style={{minHeight : '450px', paddingLeft : '5px', marginTop : '15px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'zoneWiseEventsCount');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={barDASData}
												layout={barDASLayout}
												config={{ responsive: true, displaylogo : false, }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>			
									</Col>
									<Space>&nbsp;</Space>
									{/* <Col span={24} style={{ minHeight : '450px'}}>
									<Card style={{ minHeight: '500px', padding : '5px' }}>
										<button
											style={{ marginTop : '35px', marginRight : '30px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'zoneWiseEventsCount');}} 
											className="graphZoomButton" ><FullscreenOutlined /></button>
										<Plot
											data={barDASData}
											layout={barDASLayout}
											config={{ responsive: true, displaylogo : false, }}
											style={{ width: '100%', height: '100%' }}
										/>
									</Card>								
								</Col> */}
							
								</Row>
					
							</>
							:
							data && data.dtsTimeEventData ? 
								<Row style={{
									margin: '10px',
									marginTop : '10px',
									// paddingLeft: '10px',
									// paddingRight: '10px',
									// backgroundColor: 'red',
									alignItems:'center',
								}} >
									<Col span={12} style={{ minHeight : '450px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageTemperature');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={[data.dtsTimeTempData]}
												layout={layoutDTS}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>
									</Col>
									<Col span={12} style={{ minHeight : '450px', paddingLeft : '5px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageTemperatureDepth');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={[data.dtsDepthTempData]}
												layout={layoutDTSDepth}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>
									</Col>
									<Col span={12} style={{ minHeight : '450px'}}>
										<Card style={{ minHeight: '500px', padding : '5px', marginTop : '15px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'eventTempCount');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={[data.dtsTimeEventData]}
												layout={layoutEventDTS}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>
									</Col>
									<Col span={12} style={{ minHeight : '450px', paddingLeft : '5px', marginTop : '15px'}}>
										<Card style={{ minHeight: '500px', padding : '5px' }}>
											<button
												style={{ marginTop : '35px', marginRight : '30px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'ZoneWiseTempEventsCount');}} 
												className="graphZoomButton" ><FullscreenOutlined /></button>
											<Plot
												data={barDTSData}
												layout={barDASLayout}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '100%' }}
											/>
										</Card>			
									</Col>
									<Space>&nbsp;</Space>									
								</Row>	
					
								: null }
					</>
					:
					graph =='averageAmplitude' ? 
						<Col span={24} style={{padding:'10px'}}>
							<button
								style={{margin : '10px'}}
								onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageAmplitude');}} 
								className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
							<Plot
								data={[data.dasTimeAmpData]}
								layout={layoutDAS}
								config={{ responsive: true, displaylogo : false }}
								style={{ width: '100%', height: '800px' }}
							/>
						</Col>
						:
						graph =='averageAmplitudeDepth' ? 
							<Col span={24} style={{padding:'10px'}}>
								<button
									style={{margin : '10px'}}
									onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageAmplitude');}} 
									className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
								<Plot
									data={[data.dasDepthAmpData]}
									layout={layoutDASDepth}
									config={{ responsive: true, displaylogo : false }}
									style={{ width: '100%', height: '800px' }}
								/>
							</Col>
							:
							graph =='eventCount' ? 
								<Col span={24} style={{padding:'10px'}}>
									<button
										style={{margin : '10px'}}
										onClick={()=>{handlefullSize(fullsize === false ? true:false,'eventCount');}} 
										className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
									<Plot
										data={[data.dasTimeEventData]}
										layout={layoutEventDAS}
										config={{ responsive: true, displaylogo : false }}
										style={{ width: '100%', height: '800px' }}
									/>
								</Col>
								: 
								// graph =='eventCountDepth' ? 
								// 	<Col span={24} style={{padding:'10px'}}>
								// 		<button
								// 			style={{margin : '10px'}}
								// 			onClick={()=>{handlefullSize(fullsize === false ? true:false,'eventCount');}} 
								// 			className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
								// 		<Plot
								// 			data={[data.dasData]}
								// 			layout={layoutEventDepth}
								// 			config={{ responsive: true, displaylogo : false }}
								// 			style={{ width: '100%', height: '800px' }}
								// 		/>
								// 	</Col>
								// 	: 
								graph =='zoneWiseEventsCount' ? 
									<Col span={24} style={{padding:'10px'}}>
										<button
											style={{margin : '10px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'zoneWiseEventsCount');}} 
											className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
										<Plot
											data={barDASData}
											layout={barDASLayout}
											config={{ responsive: true, displaylogo : false, }}
											style={{ width: '100%', height: '800px' }}
										/>
									</Col>
									: 
									graph =='averageTemperature' ? 
										<Col span={24} style={{padding:'10px'}}>
											<button
												style={{margin : '10px'}}
												onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageTemperature');}} 
												className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											<Plot
												data={[data.dtsTimeTempData]}
												layout={layoutDTS}
												config={{ responsive: true, displaylogo : false }}
												style={{ width: '100%', height: '800px' }}
											/>
										</Col>
										:
										graph =='averageTemperatureDepth' ? 
											<Col span={24} style={{padding:'10px'}}>
												<button
													style={{margin : '10px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'averageTemperatureDepth');}} 
													className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
												<Plot
													data={[data.dtsDepthTempData]}
													layout={layoutDTSDepth}
													config={{ responsive: true, displaylogo : false }}
													style={{ width: '100%', height: '800px' }}
												/>
											</Col>
											:
											graph =='eventTempCount' ? 
												<Col span={24} style={{padding:'10px'}}>
													<button
														style={{margin : '10px'}}
														onClick={()=>{handlefullSize(fullsize === false ? true:false,'eventTempCount');}} 
														className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
													<Plot
														data={[data.dtsTimeEventData]}
														layout={layoutEventDTS}
														config={{ responsive: true, displaylogo : false }}
														style={{ width: '100%', height: '800px' }}
													/>
												</Col>
												:
												graph =='ZoneWiseTempEventsCount' ? 
													<Col span={24} style={{padding:'10px'}}>
														<button
															style={{margin : '10px'}}
															onClick={()=>{handlefullSize(fullsize === false ? true:false,'ZoneWiseTempEventsCount');}} 
															className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
														<Plot
															data={barDTSData}
															layout={barDASLayout}
															config={{ responsive: true, displaylogo : false }}
															style={{ width: '100%', height: '800px' }}
														/>
													</Col>
													:
													null
				}
			</Content>

			<Spin fullscreen spinning={false} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}