import { DAS_SOCKET_DATA, DTS_SOCKET_DATA, FLOW_MONITORING_GRAPH_CONFIG, PRESS_SOCKET_DATA } from './constants';


export default function flowMonitoringData(state, action) {

	switch (action.type) {
	case DTS_SOCKET_DATA : 	
		return { ...state, dtsData: action.payload };

	case DAS_SOCKET_DATA : 	
		return { ...state, dasData: action.payload };

	case PRESS_SOCKET_DATA : 	
		return { ...state, pressData: action.payload};

	case FLOW_MONITORING_GRAPH_CONFIG :
		return { ...state, graphConfig : action.payload && action.payload.data ? action.payload.data[0] : {}};

	default: 
		throw Error('Unknown action: ' + action.type);
      
	}
}