import React, { useEffect, useReducer, useState } from 'react';

import { useAuth } from '../../Context';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, Empty, Form, Input, message, Modal, Row, Select, Space, Spin, Table, Tooltip, Typography } from 'antd';
import { ToolOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import axios from 'axios';
import wellZoneInfoReducer from './reducer';
import { WELL_ZONE_INFO, WELL_ZONE_INFO_CREATE, WELL_ZONE_INFO_CREATE_ERROR, WELL_ZONE_INFO_CREATE_SUCCESS, WELL_ZONE_INFO_DELETE, WELL_ZONE_INFO_DELETE_ERROR, WELL_ZONE_INFO_DELETE_SUCCESS, WELL_ZONE_INFO_ERROR, WELL_ZONE_INFO_SUCCESS, WELL_ZONE_INFO_UPDATE, WELL_ZONE_INFO_UPDATE_DATA, WELL_ZONE_INFO_UPDATE_ERROR, WELL_ZONE_INFO_UPDATE_SUCCESS } from './constants';
import { useFormik } from 'formik';

const { Title } = Typography;

export default function WellZoneInfo() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	const pipelineId = localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) ? localStorage.getItem(`${dashboardtype.toUpperCase()}_pipelineId`) : null;
	const initalState = {wellZoneInfo : [], pending: false, error: false, zoneUpdateData : {}};
	const [data, dispatch] = useReducer(wellZoneInfoReducer, initalState);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const { wellinfo, getWellInfo} = useAuth();
	const [wellInfoId, setWellInfoId] = useState(null);
	const [updateId, setUpdateId]= useState();
	const [showDeleteModal, setShowDeleteModal] = useState();
	const [deleteId, setDeleteId] = useState();
	const [startDepth, setStartDepth] = useState();
	const [endDepth, setEndDepth] = useState();
	const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));
	
	useEffect(() => {
		window.scrollTo(0, 0);
		getWellInfo(authtoken);
	}, []);

	useEffect(() => {
		loadWellZoneInfo();		
	},[wellInfoId]);

	const loadWellZoneInfo = () => {
		dispatch({ type : WELL_ZONE_INFO });
		let params = '';
		if(wellInfoId) {
			params = `&wellinfoId=${wellInfoId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellzoneinfo?$sort[id]=1${params}`,
			timeout: timeout,
			headers: { 
				Authorization:`Bearer ${authtoken}`
			}
		};
		
		axios.request(config)
			.then((response) => {
				if(response.status == 200) {
					dispatch({ type : WELL_ZONE_INFO_SUCCESS, payload : response.data });
				} 
				else {
					dispatch({ type : WELL_ZONE_INFO_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : WELL_ZONE_INFO_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const welldata =[];
	const wellInfodata =[];

	welldata.push({
		value : null,
		label : 'ALL'
	});
	wellinfo && wellinfo.map((item) => {		
		welldata.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
		wellInfodata.push({
			value : item.id,
			label : item.name.toUpperCase(),
		});
	});

	const columns = [
		{
			title: langdata && langdata.Name ? langdata.Name : 'Name',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: langdata && langdata.ZoneFrom ? langdata.ZoneFrom : 'Zone From',
			dataIndex: 'zoneFrom',
			key: 'zoneFrom',
		},
		{
			title: langdata && langdata.ZoneTo ? langdata.ZoneTo : 'Zone To',
			dataIndex: 'zoneTo',
			key: 'zoneTo',
		},
		{
			title: langdata && langdata.WellName ? langdata.WellName : 'Well Name',
			dataIndex: 'wellinfo',
			key: 'wellinfo',
		}
	];

	permission==='superadmin' || permission === 'admin' ? 
		columns.push({
			title: langdata && langdata.Actions ? langdata.Actions : 'Actions',
			key: 'action',
			render: (_,record) => (
				<Space size="middle">
					<a title={langdata && langdata.EditWellZoneInfo ? langdata.EditWellZoneInfo : 'Edit Well Zone Info'} onClick={() => {showModal(record);}} ><EditOutlined/></a>
					<a title={langdata && langdata.DeleteWellZone ? langdata.DeleteWellZone : 'Delete Well Zone'} onClick={() => {openUserModal(record);}} ><DeleteOutlined /></a>
				</Space>
			)
		}) : null;

	
	const showModal = (value) => {
		setIsEditModalOpen(true);
		setUpdateId(value.id);
		dispatch({ type : WELL_ZONE_INFO_UPDATE_DATA , payload : value});
	};
	const handleOk = () => {
		setIsEditModalOpen(false);
		setIsAddModalOpen(false);
		setShowDeleteModal(false);
		setStartDepth(null);
		setEndDepth(null);
	};
	const handleCancel = () => {
		setIsEditModalOpen(false);
		setIsAddModalOpen(false);
		setShowDeleteModal(false);
		setStartDepth(null);
		setEndDepth(null);
	};	

	const updateFormik = useFormik({
		enableReinitialize: true,
		initialValues : {
			WellName : data.zoneUpdateData.wellinfo,
			zoneName : data.zoneUpdateData.name,
			zoneFrom : data.zoneUpdateData.zoneFrom,
			zoneTo : data.zoneUpdateData.zoneTo,
			wellinfoId : data.zoneUpdateData.wellinfoId
		},
		onSubmit: (values) => {
			const {zoneName, zoneFrom, zoneTo } = values;			
			if( (zoneFrom && zoneTo && zoneName) && zoneName == null || zoneName == '' || isNaN(zoneFrom) || isNaN(zoneTo)){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			if(parseInt(zoneFrom) < startDepth){
				Modal.warning({ title : `${langdata && langdata.Startzonemustbegreaterthanorequalto ? langdata.Startzonemustbegreaterthanorequalto : 'Start zone must be greater than or equal to'} ${startDepth}`  });
				return false;
			}
			if(parseInt(zoneFrom) >= endDepth){
				Modal.warning({ title : `${langdata && langdata.Startzonemustbelessthan ? langdata.Startzonemustbelessthan : 'Start zone must be less than'} ${endDepth}`  });
				return false;
			}
			if(parseInt(zoneTo) > endDepth){
				Modal.warning({ title : `${langdata && langdata.Endzonemustbelessthanorequalto ? langdata.Endzonemustbelessthanorequalto : 'End zone must be less than or equal to'} ${endDepth}`  });
				return false;
			}
			if(parseInt(zoneTo) <= startDepth){
				Modal.warning({ title : `${langdata && langdata.Endzonemustbegreaterthan ? langdata.Endzonemustbegreaterthan : 'End zone must be greater than'} ${startDepth}`  });
				return false;
			}
			dispatch({ type : WELL_ZONE_INFO_UPDATE });
			let data = JSON.stringify({
				'zoneName': zoneName,
				'zoneFrom': parseInt(zoneFrom),
				'zoneTo': parseInt(zoneTo)
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/wellzoneinfo/${updateId}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						loadWellZoneInfo();
						Modal.success({ title: langdata && langdata.Wellzoneupdatedsuccessfully ? langdata.Wellzoneupdatedsuccessfully : 'Well zone updated successfully.' });					
						dispatch({ type : WELL_ZONE_INFO_UPDATE_SUCCESS });
						setStartDepth(null);
						setEndDepth(null);
					} else {
						dispatch({ type : WELL_ZONE_INFO_UPDATE_ERROR });
						Modal.warning({ title: langdata && langdata.Wellzonenotupdated ? langdata.Wellzonenotupdated : 'Well zone not updated' });					
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_ZONE_INFO_UPDATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellzonenotupdated ? langdata.Wellzonenotupdated : 'Well zone not updated' });					
					}
				});
			
			setIsEditModalOpen(false);
		}
	});	

	const createFormik = useFormik({
		// enableReinitialize: true,
		initialValues : {
			WellId : null,
			zoneName : null,
			zoneFrom : null,
			zoneTo : null
		},
		onSubmit: (values) => {
			const {WellId, zoneName, zoneFrom, zoneTo } = values;
			
			if( WellId == null || zoneName == null || zoneFrom == null || zoneTo == null){
				Modal.warning({title : langdata && langdata.Pleasefillallthefields ? langdata.Pleasefillallthefields : 'Please fill all the fields'});
				return false;
			}
			
			if(parseInt(zoneFrom) < startDepth){
				Modal.warning({ title : `${langdata && langdata.Startzonemustbegreaterthanorequalto ? langdata.Startzonemustbegreaterthanorequalto : 'Start zone must be greater than or equal to'} ${startDepth}`  });
				return false;
			}
			if(parseInt(zoneFrom) >= endDepth){
				Modal.warning({ title : `${langdata && langdata.Startzonemustbelessthan ? langdata.Startzonemustbelessthan : 'Start zone must be less than'} ${endDepth}`  });
				return false;
			}
			if(parseInt(zoneTo) > endDepth){
				Modal.warning({ title : `${langdata && langdata.Endzonemustbelessthanorequalto ? langdata.Endzonemustbelessthanorequalto : 'End zone must be less than or equal to'} ${endDepth}`  });
				return false;
			}
			if(parseInt(zoneTo) <= startDepth){
				Modal.warning({ title : `${langdata && langdata.Endzonemustbegreaterthan ? langdata.Endzonemustbegreaterthan : 'End zone must be greater than'} ${startDepth}`  });
				return false;
			}
			
			dispatch({ type : WELL_ZONE_INFO_CREATE });
			let data = JSON.stringify({
				'wellinfoId' : WellId,
				'zoneName': zoneName,
				'zoneFrom': parseInt(zoneFrom),
				'zoneTo': parseInt(zoneTo),
				'pipelineId' : parseInt(pipelineId)
			});

			let config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: `${api}/wellzoneinfo`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201){
						loadWellZoneInfo();
						Modal.success({ title: langdata && langdata.Wellzonesavedsuccessfully ? langdata.Wellzonesavedsuccessfully : 'Well zone saved successfully.' });					
						dispatch({ type : WELL_ZONE_INFO_CREATE_SUCCESS });
						setStartDepth(null);
						setEndDepth(null);
					} else {
						dispatch({ type : WELL_ZONE_INFO_CREATE_ERROR });
						Modal.warning({ title: langdata && langdata.Wellzonenotsaved ? langdata.Wellzonenotsaved : 'Well zone not saved' });					
					}
				})
				.catch((error) => {
					console.log(error);
					dispatch({ type : WELL_ZONE_INFO_CREATE_ERROR });
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Wellzonenotsaved ? langdata.Wellzonenotsaved : 'Well zone not saved' });					
					}
				});
			createFormik.resetForm();
			setIsAddModalOpen(false);
		}
	});	

	const handleWellInfo = (value) => {
		setWellInfoId(value);
	};
		
	const openUserModal = (value) => {
		setShowDeleteModal(true);
		setDeleteId(value.id);
	};

	const handleDelete = () => {
		dispatch({ type : WELL_ZONE_INFO_DELETE });
		let config = {
			method: 'delete',
			maxBodyLength: Infinity,
			url: `${api}/wellzoneinfo/${deleteId}`,
			timeout: timeout,
			headers: { 
				'Content-Type': 'application/json', 
				Authorization:`Bearer ${authtoken}`				},
			data : data
		};

		axios.request(config)
			.then((response) => {
				if(response.status == 200 || response.status == 201){
					loadWellZoneInfo();
					Modal.success({ title: langdata && langdata.Wellzonedeletedsuccessfully ? langdata.Wellzonedeletedsuccessfully : 'Well zone deleted successfully.' });					
					dispatch({ type : WELL_ZONE_INFO_DELETE_SUCCESS });
				} else {
					Modal.warning({ title: langdata && langdata.Wellseismictypenotdeleted ? langdata.Wellseismictypenotdeleted : 'Well zone not deleted' });					
					dispatch ({ type : WELL_ZONE_INFO_DELETE_ERROR });
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch ({ type : WELL_ZONE_INFO_DELETE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				} else {
					Modal.warning({ title: langdata && langdata.Wellseismictypenotdeleted ? langdata.Wellseismictypenotdeleted : 'Well zone not deleted' });
				}
			});
		setShowDeleteModal(false);
	};

	useEffect(() => {		
		if(createFormik.values.WellId){
			wellinfo && wellinfo.map((item) => {
				if(item.id == createFormik.values.WellId) {
					setStartDepth(item.startDepth);
					setEndDepth(item.endDepth);					
				}
			});			
		}		
		if(updateFormik.values.wellinfoId){
			wellinfo && wellinfo.map((item) => {
				if(item.id == updateFormik.values.wellinfoId) {					
					setStartDepth(item.startDepth);
					setEndDepth(item.endDepth);					
				}
			});			
		}
	},[createFormik.values.WellId, isEditModalOpen]);


	
	return (
		<Content>
			<Row style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center',
			}}
			>
				<Col span={8}>
					<Row>
						<Space>
							<ToolOutlined style={{fontSize:'30px'}}/>
						</Space>
						<Space>&nbsp;&nbsp;</Space>
						<Title level={4} style={{ paddingTop:'10px'}}>{langdata && langdata.WellZoneInfo ? langdata.WellZoneInfo : 'Well Zone Info'}</Title>
					</Row>
				</Col>
				<Col span={16}>
					<Row justify="end">
						<Col>
							{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
							<Tooltip placement="bottom" title={langdata && langdata.Filterbywellinfo ? langdata.Filterbywellinfo : 'Filter by well info'}>
								<Space>
									<Select
										style={{ minWidth: '200px', margin:'5px'}}
										showSearch
										// filterOption={filterOption}
										// placeholder={langdata && langdata.SelectuserType ? langdata.SelectuserType : 'Select user Type'}
										optionFilterProp="children"
										options={welldata}
										onSelect={handleWellInfo}
										value={wellInfoId}
									/>
								</Space>
							</Tooltip>
							{ permission==='superadmin' || permission === 'admin' ? 
								<Space>
									<Space>&nbsp;</Space>
									<Button style={{margin:'5px'}} type="primary" onClick={() => {setIsAddModalOpen(true);}}><PlusOutlined/>{langdata && langdata.AddNew ? langdata.AddNew : 'Add New'}</Button>
								</Space>
								: null }
						</Col>
					</Row>
				</Col>
			</Row>
			<Row span={24} style={{
				margin: '10px',
				paddingLeft: '10px',
				paddingRight: '10px',
				background: '#FFFFFF',
				alignItems:'center'
			}} >
				<Col span={24}>
					{ data && data.wellZoneInfo.length > 0 ? (
						<Table
							scroll={{
								x: 900,
							}}
							columns = { columns } 
							dataSource = { data.wellZoneInfo } 
						/>
					)
						:
						(
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						)}
				</Col>
			</Row>
			<Modal
				title={langdata && langdata.EditWellZoneInfo ? langdata.EditWellZoneInfo : 'Edit Well Zone Info'}
				centered
				open={isEditModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary"  onClick={updateFormik.handleSubmit}>{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.WellName ? langdata.WellName : 'Well Name'} rules={[{ required: false, message: langdata && langdata.Pleaseselectthewellname ? langdata.Pleaseselectthewellname : 'Please select the well name' }]}>
								<Input 
									name="WellName" 
									onChange={updateFormik.handleChange} 
									value={updateFormik.values.WellName}
									disabled
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ZoneName ? langdata.ZoneName : 'Zone Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthezonename ? langdata.Pleaseenterthezonename : 'Please enter the zone name' }]} required>
								<Input 
									name="zoneName" 
									placeholder={langdata && langdata.Pleaseenterthezonename ? langdata.Pleaseenterthezonename : 'Please enter the zone name'}
									onChange={updateFormik.handleChange} 
									value={updateFormik.values.zoneName}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ZoneFrom ? langdata.ZoneFrom : 'Zone From'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthezonefrom ? langdata.Pleaseenterthezonefrom : 'Please enter the zone from' }]} required>
								<Input 
									name="zoneFrom" 
									type='number'
									placeholder={`${langdata && langdata.Pleaseenterthezonefrom ? langdata.Pleaseenterthezonefrom : 'Please enter the zone from'} ${startDepth || startDepth == 0 ? startDepth : ''}`}
									onChange={updateFormik.handleChange} 
									value={updateFormik.values.zoneFrom}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ZoneTo ? langdata.ZoneTo : 'Zone To'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthezoneto ? langdata.Pleaseenterthezoneto : 'Please enter the zone to' }]} required>
								<Input 
									name="zoneTo" 
									type='number'
									placeholder={`${langdata && langdata.Pleaseenterthezoneto ? langdata.Pleaseenterthezoneto : 'Please enter the zone to'} ${endDepth || endDepth == 0 ? endDepth : ''} `}
									onChange={updateFormik.handleChange} 
									value={updateFormik.values.zoneTo}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>


			<Modal
				title={langdata && langdata.AddWellZoneInfo ? langdata.AddWellZoneInfo : 'Add Well Zone Info'}
				centered
				open={isAddModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				maskClosable={false}
				destroyOnClose= {true}
				footer={[
					// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
						<Button type="primary"  onClick={createFormik.handleSubmit}>{langdata && langdata.SAVE ? langdata.SAVE : 'SAVE'}</Button>
					</Space>
				]}
				width={700}
			>
				<Form layout="vertical" >
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item label={langdata && langdata.WellName ? langdata.WellName : 'Well Name'} rules={[{ required: true, message: langdata && langdata.Pleaseselectthewell ? langdata.Pleaseselectthewell : 'Please select the well' }]} required>
								<Select
									style={{ minWidth: '200px', margin:'5px'}}
									showSearch
									// filterOption={filterOption}
									placeholder={ langdata && langdata.Pleaseselectthewell ? langdata.Pleaseselectthewell : 'Please select the well' }
									optionFilterProp="children"
									options={wellInfodata}
									onChange={(value) => createFormik.setFieldValue('WellId', value)}
									// onSelect={handleWellInfo}
									// value={wellInfoId}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ZoneName ? langdata.ZoneName : 'Zone Name'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthezonename ? langdata.Pleaseenterthezonename : 'Please enter the zone name' }]} required>
								<Input 
									name="zoneName" 
									placeholder={langdata && langdata.Pleaseenterthezonename ? langdata.Pleaseenterthezonename : 'Please enter the zone name'}
									onChange={createFormik.handleChange} 
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ZoneFrom ? langdata.ZoneFrom : 'Zone From'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthezonefrom ? langdata.Pleaseenterthezonefrom : 'Please enter the zone from' }]} required>
								<Input 
									name="zoneFrom" 
									type='number'
									placeholder={`${langdata && langdata.Pleaseenterthezonefrom ? langdata.Pleaseenterthezonefrom : 'Please enter the zone from'} ${startDepth || startDepth == 0 ? startDepth : ''}`}
									// placeholder ={startDepth}
									onChange={createFormik.handleChange} 
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={langdata && langdata.ZoneTo ? langdata.ZoneTo : 'Zone To'} rules={[{ required: true, message: langdata && langdata.Pleaseenterthezoneto ? langdata.Pleaseenterthezoneto : 'Please enter the zone to' }]} required>
								<Input 
									name="zoneTo" 
									type='number'
									placeholder={`${langdata && langdata.Pleaseenterthezoneto ? langdata.Pleaseenterthezoneto : 'Please enter the zone to'} ${endDepth || endDepth == 0 ? endDepth : ''} `}
									onChange={createFormik.handleChange} 
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>

			<Modal title={langdata && langdata.Confirmdelete ? langdata.Confirmdelete : 'Confirm delete'} open={showDeleteModal} onOk={handleOk} onCancel={handleCancel}
				footer={[
				// eslint-disable-next-line react/jsx-key
					<Space>
						<Button onClick={handleCancel}>{langdata && langdata.CANCEL ? langdata.CANCEL : 'CANCEL'}</Button>
						<Button key="submit" type="primary" onClick={handleDelete}>{langdata && langdata.DELETE ? langdata.DELETE : 'DELETE'}</Button>
					</Space>
				]}>
				<Row style={{
					marginTop: '10px',
					marginBottom: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >
					<Col span={1}>
						<DeleteOutlined  style={{ fontSize: '14px'}}/>
					</Col>
					<Col span={23}>{ langdata && langdata.Areyousureyouwanttodeletethiszone ? langdata.Areyousureyouwanttodeletethiszone :'Are you sure you want to delete this zone?'}</Col>
				</Row>
			</Modal>

			<Spin fullscreen spinning={data.loading} />
		</Content>
	);
}