import React, { useEffect, useReducer, useState } from 'react';
import { useAuth } from '../../Context';

import { Row, Col, Select, Typography, Space, Spin,  Tooltip, Card, Breadcrumb, Input, DatePicker, message, Button, Modal, Divider, Avatar, Slider, Switch } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { AreaChartOutlined,HomeOutlined,FullscreenOutlined,FullscreenExitOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';

import Plot from 'react-plotly.js';

import englan from '../../../english.json';
import germanlan from '../../../german.json';
import arabiclan from '../../../arabic.json';
import frenchlan from '../../../french.json';
import hindilan from '../../../hindi.json';
import malayalamlan from '../../../malayalam.json';
import { useLocation, useNavigate } from 'react-router-dom';
// import * as d3 from 'd3';
// import Plotly from 'plotly.js-dist';
import Papa from 'papaparse';
// import well from './cylinder_with_jet_colors.csv';
import leak from './leak_coordinates.csv';
import moment from 'moment';
import dayjs from 'dayjs';
import axios from 'axios';
import leakDatareducer from './reducer';
import { DAS_SOCKET_DATA, DTS_SOCKET_DATA, LEAK_DETECTION_DAS_ZONE, LEAK_DETECTION_DAS_ZONE_ERROR, LEAK_DETECTION_DAS_ZONE_SUCCESS, LEAK_DETECTION_DTS_ZONE, LEAK_DETECTION_DTS_ZONE_ERROR, LEAK_DETECTION_DTS_ZONE_SUCCESS, LEAK_DETECTION_GRAPH_CONFIG } from './constants';
import { useFormik } from 'formik';

const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function LeakVisualization() {
	const dashboardtype = process.env.REACT_APP_IDENTIFIER;
	const authtoken = localStorage.getItem(`${dashboardtype.toUpperCase()}_ACCESS_TOKEN`);
	// const permission = localStorage.getItem(`${dashboardtype.toUpperCase()}_PERMISSION`);
	const { wellinfo, getWellInfo, wellzoneinfo,getWellZoneInfo, wellchanneldata, getWellChannels} = useAuth();	// const admin_roles = ['superadmin','admin','Station-In-Charge','Pipeline-HO','Pipeline-In-Charge','HQO-HSE'];
	const { state } = useLocation();
	const navigate = useNavigate();
	const [spin, setSpin] = useState(false);

	const [fullsize, setfullSize] = useState(false);
	const [graph, setGraph] = useState(null);	
	const [wellId, setWellId] = useState();
	const [dassocketurl, setDasSocketUrl] = useState(null);
	const [dtssocketurl, setDtsSocketUrl] = useState(null);

	const [depthRangeFrom, setDepthRangeFrom] = useState(null);
	const [depthRangeTo, setDepthRangeTo] = useState(null);

	const [zoneId, setZoneId] = useState(null);
	const [zonefrom, setZoneFrom] = useState(null);
	const [zoneto, setZoneTo] = useState(null);
	const [startDate, setStartDate] = useState(moment.utc(new Date().setHours(0,0,0,0)).format('YYYY-MM-DD HH:mm:ss'));
	const [endDate, setEndDate] = useState(moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'));
	const [channelId, setChannelId] = useState(null);
	const [wellFrom, setWellFrom] = useState(null);
	const [wellTo, setWellTo] = useState(null);
	const [wellCoordinatesurl, setWellCoordinatesurl] = useState(null);	
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [drawerDepthFrom, setDrawerDepthFrom] = useState();
	const [drawerDepthTo, setDrawerDepthTo] = useState();

	const [dasSocket, setDasSocket] = useState();
	const [dtsSocket, setDtsSocket] = useState();

	let dtsEnabled1 =false;
	let dasEnabled1 =false;

	const initalState = {details: null, loading: false, error: false, dtsData:null, dasData:null, pressData:null, das_data : [], dts_data : [], graphConfig : {} };
	const [data, dispatch] = useReducer(leakDatareducer, initalState);	
	
	
	let tempWellId;
	let tempdasurl;
	let tempdtsurl;
	let tempchannelId;

	let tempstartDepth;
	let tempendDepth;

	let socketDTS = null;
	let socketDAS = null;

	const langid = localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) && localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`) ? parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_LANGUAGE_ID`)) : 2;
	const langdata = langid && langid ==1 ? arabiclan : langid ==2 ? englan : langid ==3 ? frenchlan : langid ==4 ? germanlan : langid ==5 ? hindilan : langid == 6 ? malayalamlan : englan;
	const envdateformat = process.env.REACT_APP_DATE_FORMAT == undefined || process.env.REACT_APP_DATE_FORMAT == 'undefined' ? 'YYYY-MM-DD' : process.env.REACT_APP_DATE_FORMAT;
	const settingsdateformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`) == 'undefined' ? envdateformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_DATE_FORMAT`);
	const envtimeformat = process.env.REACT_APP_TIME_FORMAT == undefined || process.env.REACT_APP_TIME_FORMAT == 'undefined' ? 'HH:mm' : process.env.REACT_APP_TIME_FORMAT;
	const settingstimeformat = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`) == 'undefined' ? envtimeformat : localStorage.getItem(`${dashboardtype.toUpperCase()}_TIME_FORMAT`);
	const apiUrl = localStorage.getItem(`${dashboardtype.toUpperCase()}_API_URL`);
	const api = apiUrl && apiUrl ? apiUrl : process.env.REACT_APP_CORE_HOST;
	const envTimeout = process.env.REACT_APP_TIMEOUT == undefined || process.env.REACT_APP_TIMEOUT == 'undefined' ? 5000 : process.env.REACT_APP_TIMEOUT;
	const timeout = localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'null' || localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`) == 'undefined' ? parseInt(envTimeout) :  parseInt(localStorage.getItem(`${dashboardtype.toUpperCase()}_TIMEOUT`));

	const handleSetWell = (e , options) =>{		

		dispatch({type: DAS_SOCKET_DATA, payload:[]});
		dispatch({type: DTS_SOCKET_DATA, payload:[]});

		if(dasSocket){
			dasSocket.close();
		}
		if(dtsSocket) {
			dtsSocket.close();
		}

		tempWellId=e;
		setWellId(e);
		setDasSocketUrl(`ws://${options.dasip}:${options.dasport}`);
		setDtsSocketUrl(`ws://${options.dasip}:${options.dasport}`);

		setDepthRangeFrom(options.startDepth);
		setDepthRangeTo(options.endDepth);
		setWellFrom(options.startDepth);
		setWellTo(options.endDepth);
		setZoneId(null);
		setWellFrom(options.startDepth);
		setWellTo(options.endDepth);

		setdtsEnabled(options.dtsEnabled);
		setdasEnabled(options.dasEnabled);

		setDrawerDepthFrom(options.startDepth);
		setDrawerDepthTo(options.endDepth);
		
	};
	
	
	const welldata =[];

	wellinfo && wellinfo.map((item,index) => {

		if(item&&item.leakdetectionconfig){
			welldata.push({
				value : item.id,
				label : item.name.toUpperCase(),
				startDepth : item.startDepth,
				endDepth : item.endDepth,
				dasip: item && item.leakdetectionconfig &&item.leakdetectionconfig.dasip,
				dasport : item && item.leakdetectionconfig &&item.leakdetectionconfig.dasport,
				dtsip: item && item.leakdetectionconfig &&item.leakdetectionconfig.dtsip,
				dtsport : item && item.leakdetectionconfig &&item.leakdetectionconfig.dtsport,
				dtsminth : item && item.leakdetectionconfig &&item.leakdetectionconfig.tempThresholdsOverride ?  item.leakdetectionconfig && item.leakdetectionconfig.tempThresholds && item.leakdetectionconfig.tempThresholds.minThreshold : item.leakdetectionconfig && item.leakdetectionconfig.tempThresholds && item.leakdetectionconfig.tempThresholds.defaultminThreshold ,
				dtsmaxth : item && item.leakdetectionconfig &&item.leakdetectionconfig.tempThresholdsOverride ?  item.leakdetectionconfig && item.leakdetectionconfig.tempThresholds && item.leakdetectionconfig.tempThresholds.maxThreshold : item.leakdetectionconfig && item.leakdetectionconfig.tempThresholds && item.leakdetectionconfig.tempThresholds.defaultmaxThreshold,
				dasminth : item && item.leakdetectionconfig &&item.leakdetectionconfig.ampThresholdsOverride ?  item.leakdetectionconfig && item.leakdetectionconfig.ampThresholds && item.leakdetectionconfig.ampThresholds.minThreshold : item.leakdetectionconfig && item.leakdetectionconfig.ampThresholds && item.leakdetectionconfig.ampThresholds.defaultminThreshold,
				dasmaxth : item && item.leakdetectionconfig &&item.leakdetectionconfig.ampThresholdsOverride ?  item.leakdetectionconfig && item.leakdetectionconfig.ampThresholds && item.leakdetectionconfig.ampThresholds.maxThreshold : item.leakdetectionconfig && item.leakdetectionconfig.ampThresholds && item.leakdetectionconfig.ampThresholds.defaultmaxThreshold,
				presminth :item && item.leakdetectionconfig &&item.leakdetectionconfig.pressThresholdsOverride ?  item.leakdetectionconfig && item.leakdetectionconfig.pressThresholds && item.leakdetectionconfig.pressThresholds.minThreshold : item.leakdetectionconfig && item.leakdetectionconfig.pressThresholds && item.leakdetectionconfig.pressThresholds.defaultminThreshold,
				presmaxth : item && item.leakdetectionconfig &&item.leakdetectionconfig.pressThresholdsOverride ?  item.leakdetectionconfig && item.leakdetectionconfig.pressThresholds && item.leakdetectionconfig.pressThresholds.maxThreshold : item.leakdetectionconfig && item.leakdetectionconfig.pressThresholds && item.leakdetectionconfig.pressThresholds.defaultmaxThreshold,
				pressip: item && item.leakdetectionconfig &&item.leakdetectionconfig.pressureip,
				pressport : item && item.leakdetectionconfig &&item.leakdetectionconfig.pressureport,
				dtsEnabled : item.dtsEnabled,
				dasEnabled : item.dasEnabled
			});
			if (index === 0) {			
				tempWellId = item.id;
				tempstartDepth = item.startDepth;
				tempendDepth = item.endDepth;
				tempdasurl = `ws://${item && item.leakdetectionconfig &&item.leakdetectionconfig.dasip}:${item && item.leakdetectionconfig &&item.leakdetectionconfig.dasport}`;
				tempdtsurl = `ws://${item && item.leakdetectionconfig &&item.leakdetectionconfig.dtsip}:${item && item.leakdetectionconfig &&item.leakdetectionconfig.dtsport}`;
				dtsEnabled1 = item.dtsEnabled;
				dasEnabled1 = item.dasEnabled;
			}
		}
	});

	const [dtsEnabled, setdtsEnabled] = useState();
	const [dasEnabled, setdasEnabled] = useState();	

	useEffect(() => {
		setdtsEnabled(dtsEnabled1);
		setdasEnabled(dasEnabled1);
		setDepthRangeFrom(tempstartDepth);
		setDepthRangeTo(tempendDepth);
	},[dtsEnabled1, dasEnabled1, tempstartDepth, tempendDepth]);


	const zonelist =[{
		value:null,label:'ALL',zoneFrom:wellFrom ,zoneTo:wellTo
	}];

	wellzoneinfo && wellzoneinfo.map((item)=>{
		zonelist.push({
			value : item.id,
			label : item.zoneName.toUpperCase(),
			zoneFrom:item.zoneFrom,
			zoneTo:item.zoneTo
		});
	});

	const channellist =[{
		value:null,label:'ALL'
	}];

	wellchanneldata && wellchanneldata.map((item,index)=>{
		channellist.push({
			value: item.id,
			label : item.name

		});
		if(index==0){
			tempchannelId =  item.id;
		}
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [fullsize]);

	const getWellCoordinates =()=>{		

		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/wellcooridnatesdata?wellId=${wellId ? wellId : tempWellId}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if (response.status === 200) {
					
					setWellCoordinatesurl(response.data.url);
				}else{
					
					setWellCoordinatesurl(null);
				}
			})
			.catch((error) => {
				setWellCoordinatesurl(null);
				
				console.log(error);
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
				// setDropOpen(false);
				// setOpen(true);
				// setErrorMessasge(error.response.data.message);
			});
	};

	useEffect(() => {
		getWellInfo(authtoken);
		getWellZoneInfo(authtoken,wellId ? wellId : tempWellId);
		if(tempWellId || wellId){
			getWellChannels(authtoken, null,wellId ? wellId : tempWellId);

			getWellCoordinates();
		}

		// setWellId(tempWellId);
		// setSocketUrl(tempurl);

	},[wellId,dassocketurl,dtssocketurl,tempWellId,dasEnabled,dtsEnabled]);

	useEffect(() => {
		let params = '';		
		if(wellId || tempWellId) {
			params += `wellinfoId=${wellId ? wellId : tempWellId}`;
		
			let config = {
				method: 'get',
				maxBodyLength: Infinity,
				url: `${api}/leakdetectionconfig?${params}`,
				headers: { 
					Authorization:`Bearer ${authtoken}`	
				}
			};
		
			axios.request(config)
				.then((response) => {
					if(response.status == 200) {
						dispatch({ type : LEAK_DETECTION_GRAPH_CONFIG, payload : response.data });
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	},[wellId, tempWellId]);

	const handlefullSize = (size,graph) =>{
		setfullSize(size);
		setGraph(graph);
	};


	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());


	const webSocketDataDAS = () =>{		

		// const socket1 = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
		// socket1.close();
		if(socketDAS == null ){
			socketDAS = new WebSocket(dassocketurl ? dassocketurl : tempdasurl); // Replace with your WebSocket URL
			setDasSocket(socketDAS);
			setSpin(true);		
	
			socketDAS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};
	
			socketDAS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					dispatch({type: DAS_SOCKET_DATA, payload:newData});

				
					// setWebsocketDASData(newData);
					setSpin(false);
	
				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};
	
			socketDAS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};
	
			socketDAS.onclose = () => {
			// setTimeout(setSpin(false), 5000);	
			// setTimeout(()=> {
			// 	webSocketDataDAS();
			// }, 10000);	
				setSpin(false);
				dispatch({type: DAS_SOCKET_DATA, payload: [] });
				console.log('WebSocket connection closed');
			};		
	
			const handlePopState = () => {			
				socketDAS.close();
				dispatch({type: DAS_SOCKET_DATA, payload: [] });
			};

			const handleUrlChange = () => {
				if (socketDAS) {
					socketDAS.close();
					dispatch({type: DAS_SOCKET_DATA, payload: [] });
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDAS.close();
				dispatch({type: DAS_SOCKET_DATA, payload: [] });
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}
	};

	useEffect(() => {

		if((dassocketurl || tempdasurl) && (dasEnabled == true || dasEnabled1 == true)){						

			webSocketDataDAS();
		}
	
	}, [dassocketurl,tempdasurl]);
	
	
	useEffect(() => {

		if((dtssocketurl || tempdtsurl) && (dtsEnabled == true || dtsEnabled1 == true)){						

			webSocketDataDTS();
		}
	
	}, [dtssocketurl,tempdtsurl]);
	


	const webSocketDataDTS =()=>{
		// const socket = new WebSocket('ws://192.168.1.41:7891'); // Replace with your WebSocket URL
		// const socket1 = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl); // Replace with your WebSocket URL
		// socket1.close();

		if(socketDTS == null) {
			socketDTS = new WebSocket(dtssocketurl ? dtssocketurl : tempdtsurl);
			// console.log('socket', socketDTS);		
			setDtsSocket(socketDTS);
			setSpin(true);		


			socketDTS.onopen = () => {
				console.log('WebSocket connection opened');
				setSpin(true);
			};

			socketDTS.onmessage = (event) => {
				try {
					const newData = JSON.parse(event.data);
					// console.log('new Data', newData);	
					dispatch({type: DTS_SOCKET_DATA, payload:newData});

				
			
					// setWebsocketDTSData(newData);
					setSpin(false);

				} catch (error) {

					setSpin(false);
					console.error('Error parsing data:', error);
				}
			};

			socketDTS.onerror = (error) => {
				setSpin(false);
				console.error('WebSocket error:', error);
			};

			socketDTS.onclose = () => {
				// setTimeout(setSpin(false), 5000);
			// setTimeout(()=> {
			// 	webSocketDataDTS();
			// }, 10000);		
				setSpin(false);
				dispatch({type: DTS_SOCKET_DATA, payload: []});
				console.log('WebSocket connection closed');
			};		

			const handlePopState = () => {			
				socketDTS.close();
				dispatch({type: DTS_SOCKET_DATA, payload: []});
			};

			const handleUrlChange = () => {
				if (socketDTS) {
					socketDTS.close();
					dispatch({type: DTS_SOCKET_DATA, payload: []});
				}
			};
		
			window.addEventListener('popstate', handlePopState);

			const originalPushState = history.pushState;
			const originalReplaceState = history.replaceState;

			history.pushState = function (...args) {
				originalPushState.apply(this, args);
				handleUrlChange(); // Close socket on push
			};

			history.replaceState = function (...args) {
				originalReplaceState.apply(this, args);
				handleUrlChange(); // Close socket on replace
			};
		
			return () => {
				window.removeEventListener('popstate', handlePopState);
				socketDTS.close();
				dispatch({type: DTS_SOCKET_DATA, payload: []});
				history.pushState = originalPushState; // Restore original pushState
				history.replaceState = originalReplaceState; // Restore original replaceState
			};
		}
	};
	

	const plotDtsData = [{
		z:data && data.dtsData && data.dtsData.data,
		y:data && data.dtsData && data.dtsData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dtsData && data.dtsData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Hot',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap',
		reversescale:true
	},
	{
		type: 'scatter',
		// z:websocketdata.data,
		// x: websocketdata.times,
		// y: websocketdata.depths,
		mode: 'markers',
		transforms: [{
			type: 'filter',
			target: 'y',
			operation: '>',
		// value: 4
		}],
		name: langdata && langdata.DistanceandDepth ? langdata.DistanceandDepth :  'Distance and Depth', // Legend entry name for scatter plot
		marker: {
			size: 10, // Increase the size of the points
			color: 'brown', // Optional: Change point color if needed
			symbol : 'star-square'
		},
	//   hoverlabel: {
	//     bgcolor: 'white', // Set the tooltip background color
	//     // font: {
	//     //   color: 'white', // Set the text color
	//     // }
	//   }
	}];
	
	const plotDasData = [{

		z:data && data.dasData && data.dasData.data,
		y:data && data.dasData && data.dasData.depths,
		// y:websocketdata && websocketdata.data ? [...websocketdata.data.keys()]: [10],
		// // colorscale: customColorscale, // Use custom color scale
		x: data && data.dasData && data.dasData.times,
		// z: data.z,
		// colorscale: customColorscale, // Use custom color scale
		// x: [1,2,3,4,5,6,7,8,9],
		colorscale: 'Jet',
		// colorbar: {
		//   title: 'Intensity',
		//   tickvals: [0, 0.25, 0.5, 0.75, 1],
		//   ticktext: ['Low', 'Medium-Low', 'Medium', 'Medium-High', 'High']
		// },
		type: 'heatmap'
	},
	{
		type: 'scatter',
		// z:websocketdata.data,
		x: data && data.dasData && data.dasData.anomaly_times,
		y: data && data.dasData && data.dasData.anomaly_depths,
		mode: 'markers',
		// transforms: [{
		// 	type: 'filter',
		// 	target: 'y',
		// 	operation: '>',
		// // value: 4
		// }],
		marker: {
			size: 20, // Increase the size of the points
			color: 'rgba(135, 206, 250, 0.5)', // Optional: Change point color if needed
			symbol : 'circle',
			font: {
				color: 'black', // Set the text color
			},
		},
		name: data && data.dasData && data.dasData.anomaly_type, // Legend entry name for scatter plot
		// hoverlabel: {
		// 	bgcolor: 'white', // Set the tooltip background color
		// 	font: {
		// 		color: 'black', // Set the text color
		// 	},
		// }
	}];

	const daslayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.DASSoundWaves ? langdata.DASSoundWaves :  'DAS Sound Waves',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth ],
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};


	const dtslayout = {
		// title: 'Flow Monitoring',
		title: {
			text: langdata && langdata.DTSTemperature ? langdata.DTSTemperature : 'DTS Temperature',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		fontWeight: 800,
		autosize: true,
		xaxis: { title: langdata && langdata.Time ? langdata.Time : 'Time' },
		yaxis: { title: langdata && langdata.Depth ? langdata.Depth :'Depth', 
			// autorange: 'reversed',
			range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ tempendDepth , tempstartDepth],
		},
		// paper_bgcolor: 'transparent', // Remove background color of the entire chart
		// plot_bgcolor: 'transparent'   // Remove background color of the plot area
	};



	//bar graphs

	useEffect(() => {
		loadDasZone();
		loadDtsZone();
	},[wellId, tempWellId, startDate, endDate, channelId, tempchannelId]);	

	const loadDasZone = () => {
		dispatch({ type : LEAK_DETECTION_DAS_ZONE });
		let params='';
		if(wellId || tempWellId){
			params += `&wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalydasreports-zone?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if(response.status == 200){
					dispatch({ type : LEAK_DETECTION_DAS_ZONE_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : LEAK_DETECTION_DAS_ZONE_ERROR });
				}			
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : LEAK_DETECTION_DAS_ZONE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const loadDtsZone = () => {
		dispatch({ type : LEAK_DETECTION_DTS_ZONE });
		let params='';
		if(wellId || tempWellId){
			params += `&wellinfoId=${wellId ? wellId : tempWellId}`;
		}
		if(startDate && endDate){
			params += `&datetimeFrom=${startDate}&datetimeTo=${endDate}`;
		}
		if(channelId ||  tempchannelId){
			params += `&channelId=${channelId ? channelId : tempchannelId}`;
		}
		let config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: `${api}/anomalydtsreports-zone?${params}`,
			timeout: timeout,
			headers: {
				accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization:`Bearer ${authtoken}`
			},
			data: { 
  
			}
		};
  
		axios
			.request(config)
			.then((response) => {
				if(response.status == 200){
					dispatch({ type : LEAK_DETECTION_DTS_ZONE_SUCCESS, payload : response.data});
				} else {
					dispatch({ type : LEAK_DETECTION_DTS_ZONE_ERROR });
				}			
			})
			.catch((error) => {
				console.log(error);
				dispatch({ type : LEAK_DETECTION_DAS_ZONE_ERROR });
				if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
					message.config({
						duration : 5,
						maxCount : 1,
					});
					message.open({
						type: 'error',
						content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
					});
				}
			});
	};

	const barDASData = [
		{
			x:  data && data.das_data && data.das_data && data.das_data.zones ? data.das_data.zones : [],
			y:  data && data.das_data && data.das_data && data.das_data.event_counts ? data.das_data.event_counts : [],
			name: 'Event',
			type: 'bar',
		},
		// {
		// 	x: ['Zone 1','Zone 2','Zone 3','Zone 4','Zone 5','Zone 6','Zone 7','Zone 8','Zone 9','Zone 10'],
		// 	y: [1,2,3,4,5,6,7,8,9,0.5],
		// 	name: 'Event 2',
		// 	type: 'bar',
		// },
		
	];

	const barDTSData = [
		{
			x:  data && data.dts_data && data.dts_data && data.dts_data.zones ? data.dts_data.zones : [],
			y:  data && data.dts_data && data.dts_data && data.dts_data.event_counts ? data.dts_data.event_counts : [],
			name: 'Event',
			type: 'bar',
			marker:{
				color:'#B32D1E'
			}
		},		
	];

	const  barDASLayout = {
		barmode: 'stack',
		title: {
			text: langdata && langdata.ZoneLeakageEventsCountDAS ? langdata.ZoneLeakageEventsCountDAS :'Zone Leakage Events Count (DAS)',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			title: langdata && langdata.Zone ? langdata.Zone :'Zone',
			type: 'category',
		},
		yaxis: {
			title: langdata && langdata.EventsCount ? langdata.EventsCount : 'Events Count',
		}
	};


	const  barDTSLayout = {
		barmode: 'stack',
		title: {
			text: langdata && langdata.ZoneLeakageEventsCountDTS ? langdata.ZoneLeakageEventsCountDTS :'Zone Leakage Events Count (DTS)',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
		xaxis: {
			title: langdata && langdata.Zone ? langdata.Zone :'Zone',
			type: 'category',
		},
		yaxis: {
			title: langdata && langdata.EventsCount ? langdata.EventsCount : 'Events Count',
		}
	};

	


	// const plotRef = useRef(null);
	// const [ text, setText ] = useState();
	// const [ data3d, set3ddata ] = useState();


	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		try {
	// 			// Fetch the CSV data
	// 			// const response = await fetch('https://raw.githubusercontent.com/plotly/datasets/master/streamtube-basic.csv');
	// 			// const text = await response.text();
	// 			fetch( d3data )
	// 				.then( response => response.text() )
	// 				.then( responseText => {
	// 					setText(responseText);
	// 					return responseText;
	// 				});

	// 			//   const res = await Promise.all([
	// 			//     fetch(d3data),
	// 			//  ]);

	// 			// const csv  = require('./3ddata.csv');
	// 			// console.log('csv', res.body);
        
	// 			// Parse the CSV data
	// 			const rows = text.trim().split('\n').slice(1).map(row => {
	// 				const [x, y, z, u, v, w] = row.split(',').map(Number);
	// 				return { x, y, z, u, v, w };
	// 			});

	// 			// Extract data for the plot
	// 			const unpack = (rows, key) => rows.map(row => row[key]);
        
	// 			const data = [{
	// 				type: 'streamtube',
	// 				x: unpack(rows, 'x'),
	// 				y: unpack(rows, 'y'),
	// 				z: unpack(rows, 'z'),
	// 				u: unpack(rows, 'u'),
	// 				v: unpack(rows, 'v'),
	// 				w: unpack(rows, 'w'),
	// 				sizeref: 0.5, // Adjust this to make tubes appear as pipes
	// 				colorscale:'jet',
	// 				colorbar: {
	// 					title: 'Intensity',
	// 					tickvals: [0,90],
	// 					ticktext: ['Low', 'High'],
	// 					ticks: 'outside',
	// 					ticklen: 5
	// 				},
	// 			}];
	// 			set3ddata(data);

	// 			// const layout = {
	// 			// 	scene: {
	// 			// 		camera: {
	// 			// 			eye: {
	// 			// 				x: -0.7243612458865182,
	// 			// 				y: 1.9269804254717962,
	// 			// 				z: 0.6704828299861716
	// 			// 			}
	// 			// 		}
	// 			// 	}
	// 			// };

	// 			// Render the Plotly plot
	// 			// Plotly.newPlot(plotRef.current, data, layout);
	// 		} catch (error) {
	// 			console.error('Error fetching or parsing data:', error);
	// 		}
	// 	};

	// 	fetchData();
	// }, [text]);

	const [meshData, setMeshData] = useState({ x: [], y: [], z: [], color: [] });
	const [leakData, setLeakData] = useState({ x: [], y: [], z: [] });
	const [meshSpin, setMeshSpin] = useState(false);

	useEffect(() => {
		setMeshSpin(true);
		
		if(wellCoordinatesurl){

		
			// Load cylinder mesh coordinates with Jet colors
			Papa.parse(wellCoordinatesurl, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setMeshData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
						color: data.map(row => `rgb(${Math.round(parseFloat(row.r) * 255)}, ${Math.round(parseFloat(row.g) * 255)}, ${Math.round(parseFloat(row.b) * 255)})`)
					});
					setMeshSpin(false);
				}
			});
			Papa.parse(leak, {
				download: true,
				header: true,
				complete: (results) => {
					const data = results.data;
					setLeakData({
						x: data.map(row => parseFloat(row.x)),
						y: data.map(row => parseFloat(row.y)),
						z: data.map(row => parseFloat(row.z)),
					});
				}
			});
		}else{
			setMeshSpin(false);
			setMeshData([]);
		}
	}, [wellCoordinatesurl,wellId]);

	const numPointsPerCircle = 100;
	const numCircles = 100;
	const i = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + index % numPointsPerCircle);
	const j = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => Math.floor(index / numPointsPerCircle) * numPointsPerCircle + (index % numPointsPerCircle + 1) % numPointsPerCircle);
	const k = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => i[index] + numPointsPerCircle);
	// const l = Array.from({ length: numPointsPerCircle * (numCircles - 1) }, (_, index) => j[index] + numPointsPerCircle);

	const wellData = [
		{
			type: 'mesh3d',
			x: meshData.x,
			y: meshData.y,
			z: meshData.z,
			i: i,
			j: j,
			k: k,
			facecolor: meshData.color,  // Apply color
			colorscale: 'Jet',  // Ensure this is the correct scale for your color data
			showscale: true,
			hoverinfo: 'x+y+z',  // Customize the hover info displayed
			hoverlabel: {
				bgcolor: meshData.color,  // Background color of the tooltip
				font: {
					color: 'black'  // Text color in the tooltip
				}
			},
			// reversescale:true
		},
		{
			type: 'scatter3d',
			mode: 'markers',
			x: leakData.x,
			y: leakData.y,
			z: leakData.z,
			marker: { size: 5, color: 'red' },
			name: 'Leak Detection'
		}
		
		// {
		//   type: 'scatter3d',
		//   mode: 'markers',
		//   x: leakData.x,
		//   y: leakData.y,
		//   z: leakData.z,
		//   marker: { size: 5, color: 'red' },
		//   name: 'Leak Detection'
		// }
	];

	const wellLayout = {
		scene: {
			xaxis: { title: 'X' },
			yaxis: { title: 'Y' },
			zaxis: { title: 'Depth (m)', 
				// autorange: 'reversed',
				tickmode: 'auto', tickvals:  'auto',
				// dtick : depthRangeFrom && depthRangeTo ? (depthRangeFrom - depthRangeTo)/10 : null,
				// nticks : 10,
				// ticktext: ['3000 m', '2000 m', '1000 m', '0 m'],
				range: depthRangeFrom || depthRangeFrom == 0 && depthRangeTo  || depthRangeTo == 0 ? [depthRangeTo , depthRangeFrom ] : [ 3000 , 0 ],
			},
		},
		title: {
			text: langdata && langdata.WellVisualization3D ? langdata.WellVisualization3D : '3D Well Visualization',
			font: {
				size: 20,
				// family: 'Arial, sans-serif',
				// color: 'black',
				weight: 'bold'
			},
		},
	
	};
  

	// const layout = {
	// 	scene: {
	// 		camera: {
	// 			eye: {
	// 				x: -0.7243612458865182,
	// 				y: 1.9269804254717962,
	// 				z: 0.6704828299861716
	// 			}
	// 		}
	// 	},
	// 	title: {
	// 		text: langdata && langdata.WellVisualization3D ? langdata.WellVisualization3D : '3D Well Visualization',
	// 		font: {
	// 			size: 20,
	// 			// family: 'Arial, sans-serif',
	// 			// color: 'black',
	// 			weight: 'bold'
	// 		},
	// 	},
	// 	xaxis: {
	// 		title: 'Width',
	// 	},
	// 	yaxis: {
	// 		title: langdata && langdata.Depth ? langdata.Depth :'Depth',
	// 	}
	// };

	const handleRangefrom = (e) => {				
		if(e.target.value) {
			setDepthRangeFrom(parseInt(e.target.value));
			setDrawerDepthFrom(parseInt(e.target.value));
		} else {
			setDepthRangeFrom();
			setDrawerDepthFrom();
		}
	};

	const handleRangeTo = (e) => {
		if(e.target.value) {
			setDepthRangeTo(e.target.value);
			setDrawerDepthTo(e.target.value);
		} else {
			setDepthRangeTo();
			setDrawerDepthTo();
		}
	};		

	const handleSetZone = (e,options)=>{
		
		setZoneId(e);
		setZoneFrom(options.zoneFrom);
		setDepthRangeFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setZoneTo(options.zoneTo);
		setDepthRangeTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);
		setDrawerDepthFrom(options.zoneFrom || options.zoneFrom == 0 ? options.zoneFrom : tempstartDepth);
		setDrawerDepthTo(options.zoneTo || options.zoneTo == 0 ? options.zoneTo : tempendDepth);

	};

	const disabledRangeTime = (currentDate) => {
		if (!currentDate) {
			return {};
		}
		
		const now = moment();
		
		// Disable future time slots on the current date
		if (currentDate.isSame(now, 'day')) {
			return {
				disabledHours: () => {
					const currentHour = now.hour();
					// Disable hours after the current hour
					return Array.from({ length: 24 }, (_, i) => (i > currentHour ? i : null)).filter(i => i !== null);
				},
				// disabledMinutes: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	// Disable minutes after the current minute in the current hour
				// 	if (currentHour === now.hour()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentMinute ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
				// disabledSeconds: () => {
				// 	const currentHour = now.hour();
				// 	const currentMinute = now.minute();
				// 	const currentSecond = now.second();
				// 	// Disable seconds after the current second in the current minute
				// 	if (currentHour === now.hour() && currentMinute === now.minute()) {
				// 		return Array.from({ length: 60 }, (_, i) => (i > currentSecond ? i : null)).filter(i => i !== null);
				// 	}
				// 	return [];
				// },
			};
		}
		
		// For future dates, allow all hours, minutes, and seconds
		return {};
	};

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > dayjs().endOf('day');
	};

	const handleSetDate = (e) =>{
		if(e) {
			setStartDate(moment.utc(new Date(e[0] && e[0].$d)).format('YYYY-MM-DD HH:mm:ss'));
			setEndDate(moment.utc(new Date(e[1] && e[1].$d)).format('YYYY-MM-DD HH:mm:ss'));  
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	};

	const handleSetChannel = (value) => {
		setChannelId(value);
	};

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleDepthChange = (value) => {
		setDrawerDepthFrom(value[0]);
		setDrawerDepthTo(value[1]);
	};
	
	const formik = useFormik({
		enableReinitialize: true,
		initialValues : {
			id : data.graphConfig && data.graphConfig.id,
			WellId : data.graphConfig && data.graphConfig.wellinfoId,
			minAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.minThreshold,
			maxAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.maxThreshold,
			minAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.minThreshold,
			maxAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.maxThreshold,
			minAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.minThreshold,
			maxAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.maxThreshold,
			minTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.minThreshold,
			maxTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.maxThreshold,
			minTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.minThreshold,
			maxTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.maxThreshold,
			minTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.minThreshold,
			maxTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.maxThreshold,
			minPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.minThreshold,
			maxPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.maxThreshold,
			minPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.minThreshold,
			maxPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.maxThreshold,
			minPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.minThreshold,
			maxPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.maxThreshold,
			ampThresholdsOverride : data.graphConfig && data.graphConfig.ampThresholdsOverride,
			tempThresholdsOverride : data.graphConfig && data.graphConfig.tempThresholdsOverride,
			pressThresholdsOverride : data.graphConfig && data.graphConfig.pressThresholdsOverride,
			ampSNThresholdsOverride : data.graphConfig && data.graphConfig.ampSNThresholdsOverride,
			tempSNThresholdsOverride : data.graphConfig && data.graphConfig.tempSNThresholdsOverride,
			pressSNThresholdsOverride : data.graphConfig && data.graphConfig.pressSNThresholdsOverride,
			ampFreqThresholdsOverride : data.graphConfig && data.graphConfig.ampFreqThresholdsOverride,
			tempFreqThresholdsOverride : data.graphConfig && data.graphConfig.tempFreqThresholdsOverride,
			pressFreqThresholdsOverride : data.graphConfig && data.graphConfig.pressFreqThresholdsOverride,
			defaultminAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.defaultminThreshold,
			defaultmaxAmpThreshold : data.graphConfig && data.graphConfig.ampThresholds && data.graphConfig.ampThresholds.defaultmaxThreshold,
			defaultminAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.defaultminThreshold,
			defaultmaxAmpSNThresholds : data.graphConfig && data.graphConfig.ampSNThresholds && data.graphConfig.ampSNThresholds.defaultmaxThreshold,
			defaultminAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.defaultminThreshold,
			defaultmaxAmpFreqThresholds : data.graphConfig && data.graphConfig.ampFreqThresholds && data.graphConfig.ampFreqThresholds.defaultmaxThreshold,
			defaultminTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.defaultminThreshold,
			defaultmaxTempThreshold : data.graphConfig && data.graphConfig.tempThresholds && data.graphConfig.tempThresholds.defaultmaxThreshold,
			defaultminTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.defaultminThreshold,
			defaultmaxTempSNThresholds : data.graphConfig && data.graphConfig.tempSNThresholds && data.graphConfig.tempSNThresholds.defaultmaxThreshold,
			defaultminTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.defaultminThreshold,
			defaultmaxTempFreqThresholds : data.graphConfig && data.graphConfig.tempFreqThresholds && data.graphConfig.tempFreqThresholds.defaultmaxThreshold,
			defaultminPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.defaultminThreshold,
			defaultmaxPressureThreshold : data.graphConfig && data.graphConfig.pressThresholds && data.graphConfig.pressThresholds.defaultmaxThreshold,
			defaultminPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.defaultminThreshold,
			defaultmaxPressSNThresholds : data.graphConfig && data.graphConfig.pressSNThresholds && data.graphConfig.pressSNThresholds.defaultmaxThreshold,
			defaultminPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.defaultminThreshold,
			defaultmaxPressFreqThresholds : data.graphConfig && data.graphConfig.pressFreqThresholds && data.graphConfig.pressFreqThresholds.defaultmaxThreshold,
		},
		onSubmit: () => {
			
			const id = parseInt(formik.values.id);
			let data = JSON.stringify({
				'ampThresholds': {
					'maxThreshold': formik.values.maxAmpThreshold,
					'minThreshold': formik.values.minAmpThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxAmpThreshold,
					'defaultminThreshold': formik.values.defaultminAmpThreshold
				},
				'ampThresholdsOverride': formik.values.ampThresholdsOverride ,
				'tempThresholds': {
					'maxThreshold': formik.values.maxTempThreshold,
					'minThreshold': formik.values.minTempThreshold,
					'defaultmaxThreshold': formik.values.defaultmaxTempThreshold,
					'defaultminThreshold': formik.values.defaultminTempThreshold
				},
				'tempThresholdsOverride': formik.values.tempThresholdsOverride ,
				'ampFreqThresholds': {
					'maxThreshold': formik.values.maxAmpFreqThresholds,
					'minThreshold': formik.values.minAmpFreqThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxAmpFreqThresholds,
					'defaultminThreshold': formik.values.defaultminAmpFreqThresholds
				},
				'ampFreqThresholdsOverride': formik.values.ampFreqThresholdsOverride ,
				'tempFreqThresholds': {
					'maxThreshold': formik.values.maxTempFreqThresholds,
					'minThreshold': formik.values.minTempFreqThresholds,
					'defaultmaxThreshold': formik.values.defaultmaxTempFreqThresholds,
					'defaultminThreshold': formik.values.defaultminTempFreqThresholds
				},
				'tempFreqThresholdsOverride': formik.values.tempFreqThresholdsOverride ,
			});

			let config = {
				method: 'patch',
				maxBodyLength: Infinity,
				url: `${api}/leakdetectionconfig/${id}`,
				timeout: timeout,
				headers: { 
					'Content-Type': 'application/json', 
					Authorization:`Bearer ${authtoken}`	
				},
				data : data
			};

			axios.request(config)
				.then((response) => {
					if(response.status == 200 || response.status == 201) {
						getWellInfo(authtoken);
						Modal.success({ title: langdata && langdata.Graphdetailssuccessfullyupdated ? langdata.Graphdetailssuccessfullyupdated : 'Graph details successfully updated.' });
						setIsModalOpen(false);
						setDepthRangeFrom(drawerDepthFrom);
						setDepthRangeTo(drawerDepthTo);
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });

					}
				})
				.catch((error) => {
					console.log(error);
					if(error.code == 'ECONNABORTED' || error.code == 'ERR_NETWORK') {
						message.config({
							duration : 5,
							maxCount : 1,
						});
						message.open({
							type: 'error',
							content: langdata && langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication ? langdata.ItseemsthenetworkisunreachablePleaserefreshtheapplication :  'It seems the network is unreachable. Please refresh the application.',
						});
					} else {
						Modal.warning({ title: langdata && langdata.Unabletoupdategraphdetails ? langdata.Unabletoupdategraphdetails : 'Unable to update graph details' });
					}
				});
		}
	});			
	
	const depthMarks = {
		[wellFrom ? wellFrom : tempstartDepth] : langdata && langdata.Min ? langdata.Min : 'Min',
		[wellTo ? wellTo : tempendDepth] :langdata && langdata.Max ? langdata.Max : 'Max', 
		[drawerDepthFrom] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthFrom}</span>},
		[drawerDepthTo] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{drawerDepthTo}</span>},
	};	

	const ampMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold}</span>},
		[formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold}</span>}
	};		


	const dasFrequencyMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds}</span>} ,
		[formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds}</span>}
	};	

	const tempMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold}</span>},
		[formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold}</span>}
	};	


	const dtsFrequencyMarks = {
		0: langdata && langdata.Min ? langdata.Min : 'Min',
		20000 : langdata && langdata.Max ? langdata.Max : 'Max',
		[formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds}</span>},
		[formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds] : {style:{marginTop: '-35px',  display:'flex'}, label: <span>{formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds}</span>}
	};

	const handleReset = () => {
		formik.resetForm();
		setDrawerDepthFrom(wellFrom ? wellFrom : tempstartDepth);
		setDrawerDepthTo(wellTo ? wellTo : tempendDepth);
	};
	
	return (
		<>
			<Content>
				<Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					background: '#FFFFFF',
					alignItems:'center'
				}} >

					<Col span={8}>
						<Row>
							<Space>
								<AreaChartOutlined style={{fontSize:'30px'}}/>
								{/* </Col> */}
							</Space>
							<Space>&nbsp;&nbsp;</Space>
					
							{/* <Col style={{marginLeft:'4px'}} span={19}> */}
							<Title level={4} style={{ paddingTop:'10px'}}> {langdata && langdata.LeakDetection ? langdata.LeakDetection : 'Leak Detection'}</Title>
						</Row>
					</Col>
					
					<Col span={16}>
						<Row justify="end">
							<Col>
								<Tooltip placement="bottom" title={langdata && langdata.FilterbyWell ? langdata.FilterbyWell : 'Filter by well'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectWell ? langdata.SelectWell : 'Select Well'}
											optionFilterProp="children"
											value={wellId ? wellId : tempWellId}
											options={welldata}
											onSelect={(e, options)=>handleSetWell(e, options)}
										/>
									</Space>
								</Tooltip>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbyzone ? langdata.Filterbyzone : 'Filter by zone'}>
									<Space>
										<Select
											style={{ width: '180px', margin:'5px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
											optionFilterProp="children"
											defaultValuevalue={null}
											value={zoneId}
											options={zonelist}
											onSelect={(e, options)=>handleSetZone(e, options)}
										/>
									</Space>
								</Tooltip>
								{dasEnabled == true || dtsEnabled == true ?
									<>
										<Space>&nbsp;&nbsp;</Space>
										<Tooltip placement="bottom" title={langdata && langdata.EnterDepthRange ? langdata.EnterDepthRange:'Enter Depth Range'}>
											<Space.Compact size="middle" style={{ width : '180px', margin:'5px'}}>
												<Input type='number' value={depthRangeFrom} max={zonefrom} min={zoneto}  placeholder={langdata && langdata.DepthRangeFrom ? langdata.DepthRangeFrom: 'Depth Range From'} onChange={handleRangefrom} />
												<Input type='number' value={depthRangeTo}  max={zonefrom} min={zoneto}  placeholder={langdata && langdata.DepthRangeTo ? langdata.DepthRangeTo: 'Depth Range To'} onChange={handleRangeTo}/>
											</Space.Compact>
										</Tooltip>
										<Space>&nbsp;</Space>
										<Space>
											<Button style ={{margin:'5px'}} type='primary' onClick={showModal}><FilterOutlined /></Button>
										</Space>
									</>
									: null }
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{
					// margin: '20px',
					// marginTop : '5px',
					alignItems:'center',
					marginLeft : '20px'
				}} >
					<Col span={24}>
						<Row>
							<Breadcrumb
								items={[
									{
										href: '/dashboard',
										title: <HomeOutlined />,
									},
									{
										title: ( state.path === 'well-visualization' ? <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a> :  <a onClick={()=>{navigate(-1);}}>{langdata && langdata.WellVisualization ? langdata.WellVisualization:'Well Visualization'}</a>)
									},
									{
										title: `${langdata && langdata.LeakDetection ? langdata.LeakDetection:'Leak Detection'}`,
									},
								]}
							/>
						</Row>
					</Col>
				</Row>
				{/* <Row style={{
					margin: '10px',
					paddingLeft: '10px',
					paddingRight: '10px',
					// background: '#FFFFFF',
					alignItems:'center',
					width : '100%'
				}} > */}
				{fullsize==false ?
					<>

						<Row style={{
							margin: '20px',
							// marginTop : '5px',
							// paddingLeft: '10px',
							// paddingRight: '10px',
							// background: '#FFFFFF',
							alignItems:'center',
						}} >

							<Col span={24}>
								<Row style={{ marginTop : '-10px'}}>
									
									{dtsEnabled == true || dasEnabled == true ?

										<Card style={{ width: '100%', height: '500px' }}>
											<Row>
												{/* <Card style={{ width : '100%', height : '600px'}}>
									<Card.Grid 
										style={{ width : '100%', height : '600px'}}> */}
												{dtsEnabled == true ?
													<Col style={{ minHeight : '450px'}} span={dasEnabled == true ? 12 : 24} > 
														<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

															{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
															{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
													<> */}
												
															<button style={{marginTop:'10px',marginRight:'5px'}}
																onClick={()=>{handlefullSize(fullsize === false ? true:false,'dts');}} 
																className="graphZoomButton" ><FullscreenOutlined /></button>
															<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
																// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																className="wellInfo" >
																{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																<b style={{fontSize : '12px'}}>Currently Viewing</b>
																<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name}<br />
															</p>
															<Plot
																data={plotDtsData}
																layout={dtslayout}
																style={{ width: '100%', height: '450px' }}
																config={{
																	// displayModeBar: true,
																	displaylogo : false,
																	modeBarButtonsToRemove: [
																		'pan2d',
																		'zoom2d',
																		'select2d',
																		'zoomIn2d',
																		'zoomOut2d',
																		'autoScale2d',
																		'resetScale2d',
																		'hoverClosestCartesian',
																		'hoverCompareCartesian',
																		'zoom3d',
																		'pan3d',
																		'resetViews3d',
																		'hoverClosest3d',
																		'hoverCompare3d',
																		'orbitRotation',
																		'tableRotation',
																		'resetCameraDefault3d',
																		'resetCameraLastSave3d',
																		'lasso2d'
																	],
							
																}}
															/>
															{/* </>

													:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'500px' }} />
												} */}
															{/* </div> */}
										
														</Spin>
							
													</Col>
													: null }
												{dasEnabled == true ?								
													<Col style={{ minHeight : '450px'}} span={dtsEnabled == true ? 12 : 24} > 
											
														<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

															{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
															{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?

													<> */}
												
															<button style={{marginTop:'10px',marginRight:'5px'}}
																onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																className="graphZoomButton" ><FullscreenOutlined /></button>

															{dtsEnabled == false ?
																<p style={{marginTop:'-15px',marginLeft:'-15px', fontSize : '10px'}}
																// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																	className="wellInfo" >
																	{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																	<b style={{fontSize : '12px'}}>Currently Viewing</b>
																	<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
																</p>
																:
																<p style={{marginTop:'-15px',marginLeft:'30px', fontSize : '10px'}}
																// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
																	className="wellInfo" >
																	{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
																	<b style={{fontSize : '12px'}}>Currently Viewing</b>
																	<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
																</p>
															}
															<Plot
																data={plotDasData}
																layout={daslayout}
																style={{ width: '100%', height: '450px' }}
																config={{
																	// displayModeBar: true,
																	displaylogo : false,
																	modeBarButtonsToRemove: [
																		'pan2d',
																		'zoom2d',
																		'select2d',
																		'zoomIn2d',
																		'zoomOut2d',
																		'autoScale2d',
																		'resetScale2d',
																		'hoverClosestCartesian',
																		'hoverCompareCartesian',
																		'zoom3d',
																		'pan3d',
																		'resetViews3d',
																		'hoverClosest3d',
																		'hoverCompare3d',
																		'orbitRotation',
																		'tableRotation',
																		'resetCameraDefault3d',
																		'resetCameraLastSave3d',
																		'lasso2d'
																	],
							
																}}
															/>
															{/* </>
													:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'500px' }} />
												} */}

														</Spin>
											
													</Col>
													: null }
										
											</Row>
											{/* </Card.Grid>

								</Card> */}
										</Card>
										: null }

								</Row>
								
							
								
							</Col>
						</Row>

						<Row style={{
							margin: '20px',
							marginTop : dtsEnabled == false && dasEnabled == false ? '-25px' : null ,
							// marginTop : '5px',							
							// paddingLeft: '10px',
							// paddingRight: '10px',
							alignItems:'center',
						}} >
							<Col span={24} style={{width:'100%', height : '550px'}}>
								<Card style={{width:'100%', height : '550px', marginRight:'10px'}}>

									{/* {data3d && data3d && data3d.length > 0 ?
							<> */}
									<Spin  spinning={meshSpin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
												
										<button style={{marginTop:'10px', marginRight:'5px'}}
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'3dwell');}} 
											className="graphZoomButton" ><FullscreenOutlined /></button>
										<Plot
											style={{ width: '100%', height: '500px',minHeight:'450px' }}
											config={{
												responsive: true,
												// displayModeBar: true,
												displaylogo : false,
												modeBarButtonsToRemove: [
													'pan2d',
													'zoom2d',
													'select2d',
													'zoomIn2d',
													'zoomOut2d',
													'autoScale2d',
													'resetScale2d',
													'hoverClosestCartesian',
													'hoverCompareCartesian',
													'zoom3d',
													'pan3d',
													'resetViews3d',
													'hoverClosest3d',
													'hoverCompare3d',
													'orbitRotation',
													'tableRotation',
													'resetCameraDefault3d',
													'resetCameraLastSave3d',
													'lasso2d'
												],
			
											}}
											data={wellData}
											layout={wellLayout}
										/>
									</Spin>
									{/* </>
							:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px', backgroundColor:'#FFFFFF' }} />} */}


								</Card>
							</Col>
							{/* <Spin  spinning={barDASData && barDASData.length>0 ? false :true} size="large" tip={'Loading...'} > */}

							<Col span={24} style={{display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginTop : '10px', marginBottom : '5px'}}>
								<Space>&nbsp;</Space>
								<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
									<Space>
										<Select
											style={{ width: '200px'}}
											showSearch
											filterOption={filterOption}
											placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
											optionFilterProp="children"
											// defaultValuevalue={null}
											value={channelId ?  channelId : tempchannelId}
											options={channellist}
											onSelect={(value)=>handleSetChannel(value)}
										/>
									</Space>
								</Tooltip>
								<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
									<RangePicker 
										showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
										style={{ width: '350px', marginLeft : '10px'}}
										defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
										format={settingsdateformat + ' ' + settingstimeformat}
										placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
										disabledDate={disabledDate}
										disabledTime={disabledRangeTime}
										onChange={handleSetDate}
										allowClear={true} />
								</Tooltip>
							</Col>

							{dasEnabled && dtsEnabled  ?
								<>
									<Col span={12} style={{width:'100%', height : '550px', marginTop : '5px'}}>

										<Card style={{width:'100%', height : '550px'}}>


											{/* {barDASData && barDASData && barDASData.length > 0 ?
							<> */}
											<Spin  spinning={barDASData && barDASData.length>0 ? false :true} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

												<button style={{marginTop:'10px', marginRight:'5px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'bar');}} 
													className="graphZoomButton" ><FullscreenOutlined /></button>
												<Plot
													data={barDASData}
													layout={barDASLayout}
													// config={{ responsive: true }}
													style={{ width: '100%', height: '500px',minHeight:'450px' }}
													config={{
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
			
													}}
												/>
											</Spin>
											{/* </>
							:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px' }} />} */}

										</Card>


									</Col>
									<Col span={12} style={{width:'100%', height : '550px', marginTop : '5px', paddingLeft : '5px'}}>

										<Card style={{width:'100%', height : '550px'}}>


											{/* {barDASData && barDASData && barDASData.length > 0 ?
							<> */}
											<Spin  spinning={barDASData && barDASData.length>0 ? false :true} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

												<button style={{marginTop:'10px', marginRight:'5px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'barDTS');}} 
													className="graphZoomButton" ><FullscreenOutlined /></button>
												<Plot
													data={barDTSData}
													layout={barDTSLayout}
													// config={{ responsive: true }}
													style={{ width: '100%', height: '500px',minHeight:'450px' }}
													config={{
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
			
													}}
												/>
											</Spin>
											{/* </>
							:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px' }} />} */}

										</Card>


									</Col> 

								</> : 
							
								dasEnabled ? 
									<Col span={24} style={{width:'100%', height : '550px', marginTop : '5px'}}>

										<Card style={{width:'100%', height : '550px'}}>


											{/* {barDASData && barDASData && barDASData.length > 0 ?
<> */}
											<Spin  spinning={barDASData && barDASData.length>0 ? false :true} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

												<button style={{marginTop:'10px', marginRight:'5px'}}
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'bar');}} 
													className="graphZoomButton" ><FullscreenOutlined /></button>
												<Plot
													data={barDASData}
													layout={barDASLayout}
													// config={{ responsive: true }}
													style={{ width: '100%', height: '500px',minHeight:'450px' }}
													config={{
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],

													}}
												/>
											</Spin>
											{/* </>
:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px' }} />} */}

										</Card>


									</Col> :

									dtsEnabled ? 

										<Col span={24} style={{width:'100%', height : '550px', marginTop : '5px', paddingLeft : '5px'}}>

											<Card style={{width:'100%', height : '550px'}}>


												{/* {barDASData && barDASData && barDASData.length > 0 ?
<> */}
												<Spin  spinning={barDASData && barDASData.length>0 ? false :true} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

													<button style={{marginTop:'10px', marginRight:'5px'}}
														onClick={()=>{handlefullSize(fullsize === false ? true:false,'barDTS');}} 
														className="graphZoomButton" ><FullscreenOutlined /></button>
													<Plot
														data={barDTSData}
														layout={barDTSLayout}
														// config={{ responsive: true }}
														style={{ width: '100%', height: '500px',minHeight:'450px' }}
														config={{
															responsive: true,
															// displayModeBar: true,
															displaylogo : false,
															modeBarButtonsToRemove: [
																'pan2d',
																'zoom2d',
																'select2d',
																'zoomIn2d',
																'zoomOut2d',
																'autoScale2d',
																'resetScale2d',
																'hoverClosestCartesian',
																'hoverCompareCartesian',
																'zoom3d',
																'pan3d',
																'resetViews3d',
																'hoverClosest3d',
																'hoverCompare3d',
																'orbitRotation',
																'tableRotation',
																'resetCameraDefault3d',
																'resetCameraLastSave3d',
																'lasso2d'
															],

														}}
													/>
												</Spin>
												{/* </>
:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '500px', minHeight:'500px' }} />} */}

											</Card>


										</Col> :null

							}

							{/* </Spin> */}


						</Row>
					</> :
					graph =='dts' ? 
						<Col span={24} style={{padding:'10px'}}>
							<Spin spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

								{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
								{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
									<> */}
								<button
									onClick={()=>{handlefullSize(fullsize === false ? true:false,'dts');}} 
									className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
								<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
									// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
									className="wellInfo" >
									{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
									<b style={{fontSize : '12px'}}>Currently Viewing</b>
									<br /> Channel Name : {data && data.dtsData && data.dtsData.channel_name} <br />
								</p>
								<Plot
									data={plotDtsData}
									layout={dtslayout}
									style={{ width: '100%', height: '800px' }}
									config={{
										// displayModeBar: true,
										displaylogo : false,
										modeBarButtonsToRemove: [
											'pan2d',
											'zoom2d',
											'select2d',
											'zoomIn2d',
											'zoomOut2d',
											'autoScale2d',
											'resetScale2d',
											'hoverClosestCartesian',
											'hoverCompareCartesian',
											'zoom3d',
											'pan3d',
											'resetViews3d',
											'hoverClosest3d',
											'hoverCompare3d',
											'orbitRotation',
											'tableRotation',
											'resetCameraDefault3d',
											'resetCameraLastSave3d',
											'lasso2d'
										],
				
									}}
								/>
								{/* </>
									:<Empty style={{ width: '100%', height: '100%', minHeight:'600px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}
								{/* } */}
								{/* </div> */}
								{/* <Spin tip="Loading..." style={{display:'flex', justifyContent:'center',alignItems:'center'}} spinning={spin} /> */}
							</Spin>
						</Col>
						:	
						graph =='das' ? 
							<Col span={24} style={{padding:'10px'}}> 
								<Spin  spinning={spin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >

											
												
									{/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}> */}
									{/* {websocketdata && websocketdata.data && websocketdata.data.length > 0 ?
										<> */}
									<button
										onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
										className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
									<p style={{marginTop:'5px',marginLeft:'40px', fontSize : '10px'}}
										// onClick={()=>{handlefullSize(fullsize === false ? true:false,'das');}} 
										className="wellInfo" >
										{/* <Avatar size="small"><InfoOutlined /></Avatar> */}
										<b style={{fontSize : '12px'}}>Currently Viewing</b>
										<br /> Channel Name : {data && data.dasData && data.dasData.channel_name} <br />
									</p>
									<Plot
										data={plotDasData}
										layout={daslayout}
										style={{ width: '100%', height: '800px' }}
										config={{
											// displayModeBar: true,
											displaylogo : false,
											modeBarButtonsToRemove: [
												'pan2d',
												'zoom2d',
												'select2d',
												'zoomIn2d',
												'zoomOut2d',
												'autoScale2d',
												'resetScale2d',
												'hoverClosestCartesian',
												'hoverCompareCartesian',
												'zoom3d',
												'pan3d',
												'resetViews3d',
												'hoverClosest3d',
												'hoverCompare3d',
												'orbitRotation',
												'tableRotation',
												'resetCameraDefault3d',
												'resetCameraLastSave3d',
												'lasso2d'
											],
						
										}}
									/>
									{/* </>

										:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
									} */}
								</Spin>
											
							</Col>
							: graph =='3dwell' ? 
								<Col span={24} style={{padding:'10px'}}> 
									<Spin spinning={meshSpin} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
											
										{/* { meshData && meshData.x && meshData.x.length > 0 ?
											<> */}
										<button
											onClick={()=>{handlefullSize(fullsize === false ? true:false,'dtsline');}} 
											className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
										<Plot
											style={{ width: '100%', height: '800px' }}
											config={{
												responsive: true,
												// displayModeBar: true,
												displaylogo : false,
												modeBarButtonsToRemove: [
													'pan2d',
													'zoom2d',
													'select2d',
													'zoomIn2d',
													'zoomOut2d',
													'autoScale2d',
													'resetScale2d',
													'hoverClosestCartesian',
													'hoverCompareCartesian',
													'zoom3d',
													'pan3d',
													'resetViews3d',
													'hoverClosest3d',
													'hoverCompare3d',
													'orbitRotation',
													'tableRotation',
													'resetCameraDefault3d',
													'resetCameraLastSave3d',
													'lasso2d'
												],
					
											}}
											data={wellData}
											layout={wellLayout}
										/>
										{/* <Plot
													data={data3d}
													layout={layout}
													// config={{ responsive: true }}
													style={{ width: '100%', height: '800px' }}
													config={{
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
			
													}}
												/> */}
														
										{/* </>
											: 
										
											<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }} />
										} */}
									</Spin>
								</Col>
								: graph =='bar' ? 

									<>
										<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginLeft : '10px', marginTop : '10px'}}> 
											<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
												<Space>
													<Select
														style={{ width: '200px'}}
														showSearch
														filterOption={filterOption}
														placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
														optionFilterProp="children"
														defaultValuevalue={null}
														value={channelId ?  channelId : tempchannelId}													
														options={channellist}
														onSelect={(value)=>handleSetChannel(value)}
													/>
												</Space>
											</Tooltip>
											<Space>&nbsp;</Space>
											<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
												<RangePicker 
													showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
													style={{ width: '350px'}}
													defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
													format={settingsdateformat + ' ' + settingstimeformat}
													placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
													disabledDate={disabledDate}
													disabledTime={disabledRangeTime}
													onChange={handleSetDate}
													allowClear={true} />
											</Tooltip>
										</Col>
										<Col span={24} style={{padding:'10px'}}> 
											<Spin spinning={barDASData && barDASData.length > 0 ? false:true} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
												{/* { barDASData && barDASData.length > 0 ? 
													<> */}
												<button
													onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasline');}} 
													className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											
												<Plot
													data={barDASData}
													layout={barDASLayout}
													// config={{ responsive: true }}
													style={{ width: '100%', height: '800px' }}
													config={{
														responsive: true,
														// displayModeBar: true,
														displaylogo : false,
														modeBarButtonsToRemove: [
															'pan2d',
															'zoom2d',
															'select2d',
															'zoomIn2d',
															'zoomOut2d',
															'autoScale2d',
															'resetScale2d',
															'hoverClosestCartesian',
															'hoverCompareCartesian',
															'zoom3d',
															'pan3d',
															'resetViews3d',
															'hoverClosest3d',
															'hoverCompare3d',
															'orbitRotation',
															'tableRotation',
															'resetCameraDefault3d',
															'resetCameraLastSave3d',
															'lasso2d'
														],
			
													}}
												/>
												{/* </>
												
													: 
										
													<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
												} */}
												{/* </div> */}
											</Spin>

										</Col>
									</>
									: 
									graph =='barDTS' ? 
										<>
											<Col span={24} style={{ display : 'flex', justifyContent : 'flex-end', marginRight : '10px', backgroundColor : '#ffffff', padding : '10px', marginLeft : '10px', marginTop : '10px'}}> 
												<Space>&nbsp;</Space>
												<Tooltip placement="bottom" title={langdata && langdata.Filterbychannel ? langdata.Filterbychannel : 'Filter by channel'}>
													<Space>
														<Select
															style={{ width: '200px'}}
															showSearch
															filterOption={filterOption}
															placeholder={langdata && langdata.SelectZone ? langdata.SelectZone : 'Select Zone'}
															optionFilterProp="children"
															defaultValuevalue={null}
															value={channelId ?  channelId : tempchannelId}													
															options={channellist}
															onSelect={(value)=>handleSetChannel(value)}
														/>
													</Space>
												</Tooltip>
												<Space>&nbsp;</Space>
												<Tooltip placement="bottom" title={langdata && langdata.Pleaseselectthedateandtime ? langdata.Pleaseselectthedateandtime :'Please select the date and time'}>
													<RangePicker 
														showTime ={{hideDisabledOptions: true, format : 'YYYY-MM-DD HH'}} 
														style={{ width: '350px', marginLeft : '10px'}}
														defaultValue={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : null}
														format={settingsdateformat + ' ' + settingstimeformat}
														placeholder={[langdata && langdata.Startdateandtime ? langdata.Startdateandtime : 'Start date and time', langdata && langdata.Enddateandtime ? langdata.Enddateandtime : 'End date and time']}
														disabledDate={disabledDate}
														disabledTime={disabledRangeTime}
														onChange={handleSetDate}
														allowClear={true} />
												</Tooltip>
											</Col>
											<Col span={24} style={{padding:'10px'}}> 

												<Spin spinning={barDASData && barDASData.length > 0 ? false:true} tip={`${langdata && langdata.Loading ? langdata.Loading: 'Loading'}...`} >
													{/* { barDASData && barDASData.length > 0 ? 
														<> */}
													<button
														onClick={()=>{handlefullSize(fullsize === false ? true:false,'dasline');}} 
														className="graphZoomOutButton" ><FullscreenExitOutlined /></button>
											
													<Plot
														data={barDTSData}
														layout={barDTSLayout}
														// config={{ responsive: true }}
														style={{ width: '100%', height: '800px' }}
														config={{
															responsive: true,
															// displayModeBar: true,
															displaylogo : false,
															modeBarButtonsToRemove: [
																'pan2d',
																'zoom2d',
																'select2d',
																'zoomIn2d',
																'zoomOut2d',
																'autoScale2d',
																'resetScale2d',
																'hoverClosestCartesian',
																'hoverCompareCartesian',
																'zoom3d',
																'pan3d',
																'resetViews3d',
																'hoverClosest3d',
																'hoverCompare3d',
																'orbitRotation',
																'tableRotation',
																'resetCameraDefault3d',
																'resetCameraLastSave3d',
																'lasso2d'
															],
			
														}}
													/>
													{/* </>
												
														: 
										
														<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%', height: '100%', minHeight:'600px' }}/>
													} */}
													{/* </div> */}
												</Spin>

											</Col>
										</>
										:null}

				<Modal
					title={langdata && langdata.GraphConfigurations ? langdata.GraphConfigurations: 'Graph Configurations' }
					open={isModalOpen} 
					onOk={handleOk} 
					onCancel={handleCancel}
					maskClosable={false}
					footer={[
						<>
							<Row style={{
								margin: '10px',
								paddingLeft: '10px',
								paddingRight: '10px',
								background: '#FFFFFF',
								alignItems: 'center',
								marginTop : '10px'
							}}
							>
								<Col span={8}>
									{/* <Row>
										<Space>
											<Button onClick={handleReset}>RESET</Button>
										</Space>
									</Row> */}
								</Col>
								<Col span={16}>
									<Row justify="end">
										<Col>
											{/* <Text style={{ display: 'flow', paddingTop: '4px', textAlign: 'end',  marginRight: '4px' }}>User Type:</Text> */}
											<Space>
												<Button style={{ margin : '5px'}} onClick={handleCancel}>{langdata && langdata.CLOSE ? langdata.CLOSE : 'CLOSE'}</Button>
											</Space>
											<Space>&nbsp;</Space>
											<Space>
												<Button onClick={formik.handleSubmit} type="primary" style={{ margin : '5px'}} >{langdata && langdata.UPDATE ? langdata.UPDATE : 'UPDATE'}</Button>
											</Space>
										</Col>
									</Row>
								</Col>
							</Row>
						</>
					]}
					width={900}
				>		
					<Divider/>
					<Row gutter={24}>
						<Col span={23}>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DAS ? langdata.DAS:'DAS'}</b></Typography>
								: 
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
							}
						</Col>
						<Col  justify="end"  span={1}>
							<Tooltip placement="bottom" title={langdata && langdata.Reset ? langdata.Reset : 'Reset'}>
								<Avatar size="small" style={{ backgroundColor : '#1677FF'}}>							
									<ReloadOutlined onClick={handleReset} />	
								</Avatar>
							</Tooltip>
						</Col>
					</Row>
					{dasEnabled == true ? 
						<>
							<Row gutter={24} >
								<Col span={12}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.AmplitudeV ? langdata.AmplitudeV: 'Amplitude (V)'}</Typography>
									<Slider
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpThreshold', value[0]);formik.setFieldValue('maxAmpThreshold', value[1]);}} 
										marks={ampMarks} 
										disabled = {formik.values.ampThresholdsOverride == true ? false : true}
										value={[formik.values.ampThresholdsOverride == true ? formik.values.minAmpThreshold :  formik.values.defaultminAmpThreshold, formik.values.ampThresholdsOverride == true ? formik.values.maxAmpThreshold : formik.values.defaultmaxAmpThreshold]}/>
								</Col>
								<Col span={12}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider
										style={{marginTop:'20px'}} 
										range step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minAmpFreqThresholds', value[0]);formik.setFieldValue('maxAmpFreqThresholds', value[1]);}} 
										marks={dasFrequencyMarks}
										disabled = {formik.values.ampFreqThresholdsOverride == true ? false : true}
										value={[formik.values.ampFreqThresholdsOverride == true ? formik.values.minAmpFreqThresholds : formik.values.defaultminAmpFreqThresholds, formik.values.ampFreqThresholdsOverride == true ? formik.values.maxAmpFreqThresholds : formik.values.defaultmaxAmpFreqThresholds]}/>
								</Col>
								<Col span={12}>
									<Row>
										<Space>
											<Tooltip  placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampThresholdsOverride', value);}}
													checked={formik.values.ampThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={12}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small" 
													onClick={(value) => {formik.setFieldValue('ampFreqThresholdsOverride', value);}}
													checked={formik.values.ampFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
							</Row>
							<Divider/>
						</>
						: null }
					{dtsEnabled == true ?
						<>
							{dasEnabled == true ?
								<Typography strong style={{fontSize:'16px', color:'grey'}}><b>&#9658; {langdata && langdata.DTS ? langdata.DTS:'DTS'}</b></Typography>
								: null }
							<Row gutter={24} >
								<Col span={12}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.TemperatureC ? langdata.TemperatureC: 'Temperature (°C)'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempThreshold', value[0]);formik.setFieldValue('maxTempThreshold', value[1]);}} 
										marks={tempMarks}
										disabled = {formik.values.tempThresholdsOverride == true ? false : true}
										value={[formik.values.tempThresholdsOverride == true ? formik.values.minTempThreshold : formik.values.defaultminTempThreshold, formik.values.tempThresholdsOverride == true ? formik.values.maxTempThreshold : formik.values.defaultmaxTempThreshold]}
									/>
								</Col>
								<Col span={12}>
									<Typography style={{ marginTop : '15px'}}>{langdata && langdata.CutoffFrequencyHz ? langdata.CutoffFrequencyHz: 'Cutoff Frequency (Hz)'}</Typography>
									<Slider 
										style={{marginTop:'20px'}}
										range 
										step={10} 
										max={20000} 
										onChange={(value) => {formik.setFieldValue('minTempFreqThresholds', value[0]);formik.setFieldValue('maxTempFreqThresholds', value[1]);}} 
										marks={dtsFrequencyMarks}
										disabled = {formik.values.tempFreqThresholdsOverride == true ? false : true}
										value={[formik.values.tempFreqThresholdsOverride == true ? formik.values.minTempFreqThresholds : formik.values.defaultminTempFreqThresholds, formik.values.tempFreqThresholdsOverride == true ? formik.values.maxTempFreqThresholds : formik.values.defaultmaxTempFreqThresholds]}
									/>
								</Col>
								<Col span={12}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch size="small"
													onClick={(value) => {formik.setFieldValue('tempThresholdsOverride', value);}}
													checked={formik.values.tempThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
								<Col span={12}>
									<Row>
										<Space>
											<Tooltip placement="bottom" title={langdata && langdata.Override ? langdata.Override : 'Override'}>
												<Switch 
													size="small"
													onClick={(value) => {formik.setFieldValue('tempFreqThresholdsOverride', value);}}
													checked={formik.values.tempFreqThresholdsOverride}/>
											</Tooltip>
										</Space>
									</Row>
								</Col>
							</Row>
							<Divider/>
						</>
						: null }
					<Row gutter={24} >
						<Col span={24}>
							<Typography style={{ marginTop : '15px'}}>{langdata && langdata.Depth ? langdata.Depth +' (m)' : 'Depth (m)'}</Typography>
							<Slider style={{marginTop:'20px'}} range max={wellFrom ? wellFrom : tempendDepth} onChange={handleDepthChange} marks={depthMarks} value={[drawerDepthFrom ? drawerDepthFrom : depthRangeFrom, drawerDepthTo ? drawerDepthTo : depthRangeTo]} />
						</Col>
					</Row>
				</Modal>
					
			</Content>

			<Spin fullscreen spinning={false} />

			{/* Add Vulnerable Point Details Modal Starts */}
			
			{/* Delete User Modal Ends */}
		</>
	);
}